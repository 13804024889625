const imgLoading = (bgColor: string, isLoading: boolean, isSmall?: boolean) => {
    const marginLeftIsSmallAndLoading = isSmall && isLoading ? '10%' : isLoading ? '12%' : 0;
    return {
        backgroundColor: bgColor,
        marginTop: 10,
        marginLeft: marginLeftIsSmallAndLoading,
    };
};

const container = (bgColor: string) => {
    return {
        position: 'absolute' as const,
        bottom: '10%',
        minWidth: '100%',
        backgroundColor: bgColor,
    };
};

const img = (collapsed: boolean) => {
    return {
        width: collapsed ? 80 : 120,
        height: collapsed ? 50 : 70,
        marginLeft: collapsed ? '6%' : '10%',
    };
};

export default {
    imgLoading,
    container,
    img,
};
