import {AxiosInstance} from 'axios';
import {CustomException} from '@core/modules/common/infra/error';
import {httpMessage} from '@core/modules/common/infra/message';

import {Paths} from './keys';
import { ValidateCataGadpGateway } from '../domain/validateCataGadp.gateway';

export class ValidateCataGadpHttpGateway implements ValidateCataGadpGateway {
    constructor(private http: AxiosInstance) {}

    async verify(params: ValidateCataGadpInterface): Promise<number> {
        return this.http
            .post(Paths.default, { schedule_id: params })
            .then((res) => {
                if (!res?.data) {
                    return res?.data
                }
                return res?.data?.bplidassignedToInvoice
                // return true
            })
            .catch((error) => {
                console.log(error)
                if (error?.status !== 409) {
                    return 0
                }
                throw new CustomException(httpMessage.updateError('create invoice'));
            });
    }
}
