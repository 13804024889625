const div = {
    display: 'flex',
    justifyContent: 'center',
};

const img: React.CSSProperties = {
    width: '100%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#04ACCF',
};

const container: React.CSSProperties = {
    position: 'absolute',
    top: '34%',

    textAlign: 'start',
    marginLeft: '22%',
};

const title = {
    color: '#FFF',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const details = {
    color: '#FFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '20%',
};

const card: React.CSSProperties = {
    marginTop: '20%',
    textAlign: 'center',
    background: '#FFF',
    margin: 10,
    padding: 10,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
};

const row = {
    height: '100vh',
    width: '198vh',
};

const col = {
    margin: 0,
    padding: 0,
    height: '100vh',
};

const colSecond = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const cardTitle: React.CSSProperties = {
    color: '#054B7C',
    textAlign: 'center',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52.5px',
    marginTop: '4%',
};

const cardSubTitle: React.CSSProperties = {
    color: '#000000',
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '27.5px',
    maxWidth: '480px',
};

const formTitle: React.CSSProperties = {
    color: '#000000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '20.5px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginTop: '12%',
};

const cardFormFlex: React.CSSProperties = {
    marginTop: '10%',
};

export default {
    row,
    col,
    colSecond,
    div,
    img,
    container,
    title,
    details,
    card,
    cardTitle,
    cardSubTitle,
    cardFormFlex,
    formTitle,
};
