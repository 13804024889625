import { InputDefault } from '@/@presentation/components/form';
import { Form as AntForm, FormInstance, Row } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import TextArea from 'antd/es/input/TextArea';
import ReactInputMask from 'react-input-mask';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';

interface IProps {
  states: ProcedureContextData['states'];
  searchAddress: (zip: string) => void;
  isConfirm: boolean;
  form: FormInstance<any>
}

export const Address: React.FC<IProps> = (props) => {
  const formItems = props.form.getFieldsValue()

  return (
    <>
      <Row>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'CEP',
                value: formItems.addressZipCode || ''
              }} />
            ) : (
              <AntForm.Item label="CEP" name="addressZipCode">
                <ReactInputMask
                  placeholder="000000-00"
                  mask="99999-999"
                  name="addressZipCode"
                  maskChar="_"
                  onChange={(e) => props.searchAddress(e.target.value)}
                  disabled={props.isConfirm}
                  style={{
                    borderRadius: 20,
                    height: 40,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    paddingLeft: 10,
                    borderColor: '#D9D9D9'
                  }}
                />
              </AntForm.Item>
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Rua',
                value: formItems.addressStreet || ''
              }} />
            ) : (
              <InputDefault
                label="Rua"
                name="addressStreet"
                placeholder="Digite o nome da rua"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 380,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Bairro',
              value: formItems.addressNeighborhood || ''
            }} />
          ) : (
            <InputDefault
              label="Bairro"
              name="addressNeighborhood"
              placeholder="Bairro"
              required={props?.states?.searchedAddress?.cep ? true : false}
              nativeProps={{
                style: styles.defaultInput,
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
      <Row>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Número',
                value: formItems.addressNumber || ''
              }} />
            ) : (
              <InputDefault
                label="Número"
                name="addressNumber"
                placeholder="Número"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 100,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Complemento',
                value: formItems.addressComplement || ''
              }} />
            ) : (
              <InputDefault
                label="Complemento"
                name="addressComplement"
                placeholder="Complemento"
                nativeProps={{
                  style: {
                    width: 130,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Cidade',
                value: formItems.addressCity || ''
              }} />
            ) : (
              <InputDefault
                label="Cidade"
                name="addressCity"
                placeholder="Cidade"
                required={props?.states?.searchedAddress?.cep ? true : false}
                nativeProps={{
                  style: {
                    width: 200,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Estado',
              value: formItems.addressState || ''
            }} />
          ) : (
            <InputDefault
              label="Estado"
              name="addressState"
              placeholder="Estado"
              required={props?.states?.searchedAddress?.cep ? true : false}
              nativeProps={{
                style: styles.defaultInput,
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
      {
        props.isConfirm ? (
          <ConfirmationView item={{
            label: 'Alergias e patologias',
            value: formItems.allergieAndPathologies || ''
          }} />
        ) : (
          <AntForm.Item name="allergieAndPathologies" label="Alergias e patologias">
            <TextArea
              disabled={props.isConfirm}
              placeholder="Alergias e patologias..."
              style={{ ...styles.defaultInput, borderRadius: 20 }}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </AntForm.Item>
        )
      }
    </>
  )
};
