import { IScheduleGeneralItens, TMedicalProcedures } from "../domain/procedure.entities";
import { ProcedureGateway } from "../domain/procedure.gateway";

export class MedicalProcedureUseCase {
    constructor(private gate: ProcedureGateway) {}

    async execute(searchGeneral: IScheduleGeneralItens): Promise<TMedicalProcedures[]> {
        return this.gate.getAllMedicalProcedure(searchGeneral);
    }

    setProcedurePrincipal(code: string, procedures: TMedicalProcedures[]) {
        let first = false;
        const newProcedures = procedures.map((procedure) => {
            if (procedure.code === code && !first) {
                first = true
                return {
                    ...procedure,
                    isPrincipal: true,
                };
            }

            if (procedure.code !== code && procedure.isPrincipal) {
                return {
                    ...procedure,
                    isPrincipal: false,
                };
            }

            return procedure;
        });

        return newProcedures;
    }

    removeProcedure(code: string, procedures: TMedicalProcedures[]) {
        const newProcedures = procedures.filter((procedure) => procedure.code !== code);
        const isPrincipal = newProcedures.filter((item) => item.isPrincipal === true);

        if (isPrincipal) {
            return newProcedures.map((item, index) => {
                if (index === 0) {
                    return {
                        ...item,
                        isPrincipal: true
                    }
                } else {
                    return {
                        ...item
                    }
                }
            })
        }

        return newProcedures;
    }

    async getChargeMethods(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getAllChargeMethods(searchGeneral);
    }

    async getProcedureTypes(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getAllProcedureTypes(searchGeneral);
    }

    async getProcedureLocation(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getAllProcedureLocation(searchGeneral);
    }

    async getGenres(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getGenres(searchGeneral);
    }

    async getRelationships(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getRelationships(searchGeneral);
    }

    async getDoctors(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getDoctors(searchGeneral);
    }

    async getNationality(searchGeneral: IScheduleGeneralItens) {
        return this.gate.getNationality(searchGeneral);
    }

    async getPlans(id: string) {
        return this.gate.getPlans(id);
    }

    async getCompanies() {
        return this.gate.getCompanies();
    }

    async getReasonCancelSchedule() {
        return this.gate.getReasonCancelSchedule();
    }
}
