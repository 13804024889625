import { Row, Typography, Form as AntForm, DatePicker, TimePicker, Select } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import moment from 'moment';
import { FormInstance } from 'antd/lib';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';

interface IProps {
  states: ProcedureContextData['states'];
  isConfirm: boolean;
  form: FormInstance<any>;
}

export const DateHourLocation: React.FC<IProps> = (props) => {
  const formItems = props.form.getFieldsValue()

  return (
    <div>
      <Typography style={styles.typography}>Data, Hora, Local</Typography>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Data do procedimento',
              value: moment(formItems.procedureDate?.toDate())?.format('DD/MM/YYYY') || ''
            }} />
          ) : (
            <AntForm.Item
              name={'procedureDate'}
              label="Data do procedimento"
              style={{
                marginRight: 20,
              }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                style={{ ...styles.defaultInput, borderRadius: 20 }}
                format="DD/MM/YYYY"
                disabled={props.isConfirm}
                allowClear={false}
              />
            </AntForm.Item>
          )
        }
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Horário',
              value: formItems.procedureTime
            }} />
          ) : (
            <AntForm.Item
              style={{
                marginRight: 20,
              }}
              name={'procedureTime'}
              label="Horário"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <input
                type="time"
                style={{
                  borderRadius: 20,
                  border: '1px solid #d9d9d9',
                  height: 40,
                }}
              />
            </AntForm.Item>
          )
        }
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Local do procedimento',
              value: props?.states?.procedureLocation?.filter(
                item => item?.value === formItems?.procedureLocation || item?.value === formItems?.procedureLocation?.value
              )[0]?.label
            }} />
          ) : (
            <AntForm.Item name="procedureLocation" label="Local do procedimento" rules={[{ required: true }]}>
              <Select
                disabled={props.isConfirm}
                data-testid="select-procedure-charge"
                style={{ ...styles.defaultInput, minWidth: 300 }}
                placeholder="Selecione"
                showSearch
                filterOption={(input, option: any) => {
                  return (
                    option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                    option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                    option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  );
                }}
              >
                {
                  props.states.procedureLocation.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </AntForm.Item>
          )
        }
      </Row>
    </div>
  )
};
