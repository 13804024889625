import { Col, Typography, Row, Divider, Space, Tooltip, Input, message } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
const { Text } = Typography;
import style from './style';

import { Schedule } from '@/@core/modules/schedule/domain/schedule.entities';
import { Button } from '@/@presentation/components/form';
import React from 'react';
import schedule from '@/@core/modules/schedule/infra/container.registry';
import { useProcedure } from '@/@presentation/modules/procedure/context/procedure';

interface IDetails {
    data: Schedule | undefined;
}

const titleFormatted = (name: string, tussCode: string, xs: any) => {
    if (xs) return `${tussCode} - ${name.substring(0, 10)}... `;
    if (!xs && name.length > 60) return `${tussCode} - ${name.substring(0, 60)}... `;
    if (!xs) return `${tussCode} - ${name}`;
};

const ProcedureTags = (props: IDetails) => {
    const { xs } = useBreakpoint();
    return (
        <Space size={[0, 8]} wrap>
            {props?.data?.procedures?.map((procedure, index) => (
                <Tooltip
                    key={index}
                    placement={xs ? 'top' : 'leftBottom'}
                    title={`${procedure.name} TUSS CODE: ${procedure.tussCode}`}
                >
                    <span
                        key={index}
                        color={'#EDF6F9'}
                        style={{
                            color: '#054B7C',
                            fontSize: 16,
                            fontStyle: 'normal',
                            fontWeight: 50,
                            lineHeight: 'normal',
                            letterSpacing: 0.65,
                        }}
                    >
                        {titleFormatted(procedure.name, procedure.tussCode, xs)}
                    </span>
                </Tooltip>
            ))}
        </Space>
    );
};

const Details = (props: IDetails) => {
    const [isEditEmail, setIsEditEmail] = React.useState(false);
    const [emailIsValid, setEmailIsValid] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const { states } = useProcedure();

    const payment_billing: {
        last_email_sended: string;
        last_email_sended_date: string;
        url_billing: string;
        method_code: string;
    } = states.data?.payment_billing;

    const handleEmail = (text: string) => {
        setEmail(text)
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (regex.test(text)) {
            setEmailIsValid(true)
        } else {
            setEmailIsValid(false)
        }
    }

    const handleSubmitEmail = async () => {
        const defineEmail = () => {
            if (email && emailIsValid) {
                return email
            } else {
                return payment_billing?.last_email_sended
            }
        }

        const sendedFor = defineEmail()

        if (!sendedFor) {
            return message.error(`O email ${sendedFor} é inválido!`)
        }


        await schedule.resendBilling.execute(sendedFor, props.data!.id).then(() => {
            message.success(`Email enviado para ${sendedFor} com sucesso!`)
        }).catch(() => {
            message.error('Erro ao enviar email!')
        });

        setEmail('')
        setEmailIsValid(false)

        // notificação que foi enviado
        setIsEditEmail(false)

        setTimeout(() => location.reload(), 2500)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(String(payment_billing?.url_billing)).then(() => {
            message.success('Link em cópia na área de transferência!')
        })
    }

    function formatDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        return date.toLocaleDateString('pt-BR', options);
    }

    return (
        <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ paddingLeft: 40 }}>
            <Row style={style.rowTitle}>
                <Text style={style.title}>Detalhe do agendamento</Text>
                <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Row style={{ marginBottom: 10, marginTop: 10 }}>
                        <Text style={style.procedureTitle}>Procedimento</Text>
                    </Row>
                    <Row style={style.procedureRow}>
                        <Text style={style.procedureTitle}>
                            <ProcedureTags data={props?.data} />
                        </Text>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Row>
                        <Space direction="horizontal" size="small">
                            <img height={27} width={27} src="/warning-blue.svg" />
                            <img height={27} width={27} src="/check.svg" />
                            <img height={27} width={27} src="/file-plus-blue.svg" />
                        </Space>
                    </Row>
                </Col>
                <Divider style={{ marginTop: 15 }} />
            </Row>

            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Row style={{ marginTop: -10, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Paciente</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.patient}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Valor</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.total}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Nome do tomador da nota</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>
                            {props?.data?.receiverName !== ''
                                ? props?.data?.receiverName
                                : 'Não informado'}
                        </Text>
                    </Row>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Row style={{ marginTop: -10, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Status</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.status}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Valor pago</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.amountPaid}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Número da nota fiscal</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>
                            {props?.data?.taxNoteNumber ?? 'Não informado'}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <Text style={style.title}>Detalhe do pagamento</Text>
            <Row style={{ marginTop: -40 }}>
                <Col>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        {
                            isEditEmail ? (
                                <Text style={style.procedureTitle}>Enviar novo email para:</Text>
                            ) : (
                                <Text style={style.procedureTitle}>Email enviado para:</Text>
                            )
                        }
                    </Row>
                    <Row style={{
                        ...style.rowTitle,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                            isEditEmail ? (
                                <Input
                                    name='email'
                                    placeholder='Digite o novo email'
                                    required
                                    onChange={(e) => handleEmail(e.target.value)}
                                />
                            ) : (
                                <Text style={style.textDetails}>{payment_billing?.last_email_sended}</Text>
                            )
                        }
                    </Row>
                    <Row>
                        {
                            isEditEmail ? (
                                <>
                                    <Button
                                        text='Salvar e enviar'
                                        loading={false}
                                        size='small'
                                        disabled={!emailIsValid}
                                        onClick={() => handleSubmitEmail()}
                                    />
                                    <Button
                                        text='Cancelar'
                                        onClick={() => setIsEditEmail(false)}
                                        nativeProps={{
                                            style: {
                                                backgroundColor: '#FF6D6D',
                                                boxShadow: `0 0 0 5px #FF6D6D`,
                                                marginLeft: 15
                                            },
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        loading={false}
                                        text='Editar'
                                        size='small'
                                        disabled={!payment_billing?.last_email_sended}
                                        onClick={() => setIsEditEmail(true)}
                                        nativeProps={{
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                    />
                                    <Button
                                        loading={false}
                                        text='Reenviar'
                                        size='small'
                                        disabled={!payment_billing?.last_email_sended}
                                        onClick={() => handleSubmitEmail()}
                                        nativeProps={{
                                            style: {
                                                marginTop: 10,
                                                marginLeft: 15
                                            }
                                        }}
                                    />
                                </>
                            )
                        }
                    </Row>
                </Col>
                <Col style={{ marginLeft: 30 }}>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Enviado em:</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{payment_billing?.last_email_sended_date ? formatDate(new Date(payment_billing?.last_email_sended_date)) : 'Não encontrado'}</Text>
                    </Row>
                </Col>
                <Col style={{ marginLeft: 30 }}>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Forma de pagamento:</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{payment_billing?.method_code ?? 'Não encontrado'}</Text>
                    </Row>
                    <Row>
                        <Button
                            loading={false}
                            text='Copiar link de pagamento'
                            size='small'
                            disabled={!payment_billing?.url_billing}
                            onClick={() => handleCopy()}
                            nativeProps={{
                                style: {
                                    marginTop: 10
                                }
                            }}
                        />
                    </Row>
                </Col>
            </Row>
            <Divider />
        </Col>
    );
};

export default Details;
