import {Route, Navigate, useLocation} from 'react-router-dom';
import Layout from '@presentation/components/layout';
import notAuthenticated from './notAuthenticated';

import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';
import {paths as pathsHome} from '@presentation/modules/schedule/routes';
import {paths as pathsAdministration} from '@presentation/modules/administration/routes';
import { paths as pathProcedure } from '@presentation/modules/procedure/routes';

const paths = [...pathsHome, ...pathsAdministration, ...pathProcedure];

const routes = paths?.map((route) => (
    <Route
        key={route.key}
        path={route.key}
        element={<RequireAuth permission={route.permission}>{route.element}</RequireAuth>}
    />
));

interface IRequireAuth {
    children: JSX.Element;
    permission: ENUM_PERMISSIONS[];
}

function RequireAuth(props: IRequireAuth) {
    const location = useLocation();
    const route = notAuthenticated.defaultRoute;

    if (props.permission) {
        return <Layout permission={props.permission}>{props.children}</Layout>;
    }

    return <Navigate to={route.key} state={{from: location}} replace />;
}

const home = paths.filter((item) => item.key === '/home')[0] || undefined;

export default {
    routes: routes,
    paths: paths,
    defaultRoute: home,
};
