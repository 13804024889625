import React from 'react';
import {Button, Modal as AntModal, ModalProps, Row} from 'antd';

interface IModal extends Omit<ModalProps, 'onOk' | 'onCancel'> {
    visible: boolean
    children: React.ReactNode;
    title: string;
    handleOk: () => void;
    handleCancel: () => void;
    paymentCustomerName: string
    paymentType: string
}

const ModalConfirmationNf: React.FC<IModal> = (props) => {        

    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={props.title}
            open={props.visible}
            centered={true}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
            width={700}
            footer={[
                <Button key="back" data-testid="modal-form-btn-cancel" style={{borderColor: '#de1010', color: '#de1010'}} onClick={props.handleCancel}>
                    Cancelar
                </Button>,
                <Button key="back" data-testid="modal-form-btn-cancel" style={{backgroundColor: '#298f46', color: '#fff'}} onClick={props.handleOk}>
                    Confirmar
                </Button>
            ]}
        >
            <Row>
                <p>Os dados do tomador estão corretos para a emissão da Nota Fiscal?</p>
            </Row>
            <Row>
                <p style={{ marginTop: 10, fontSize: 15, marginRight: 10, fontWeight: '700'}}>Tomador:</p>
                <p style={{ marginTop: 10, fontSize: 16}}>{props.paymentCustomerName.toUpperCase()}</p>
            </Row>
            <Row>
                <p style={{ marginTop: 10, fontSize: 15, marginRight: 10, fontWeight: '700'}}>Forma de Pagamento:</p>
                <p style={{ marginTop: 10, fontSize: 16}}>{props.paymentType}</p>
            </Row>
        </AntModal>
    );
};

export {ModalConfirmationNf};
