import React from 'react';
import {useNavigate} from 'react-router-dom';
import {theme, Skeleton} from 'antd';
import style from './style';
import {paths} from '@presentation/modules/route/helpers';
import authentication from '@presentation/modules/route/authentication';

interface IMenu {
    isSmall: boolean;
    collapsed: boolean;
    selectItem: string;
    setSelectItem: React.Dispatch<React.SetStateAction<string>>;
}

const Menu: React.FC<IMenu> = (props: IMenu) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>([]);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    const Loading = () => {
        const SkeletonItem = () => {
            if (props.collapsed && !props.isSmall) return <></>;
            return (
                <Skeleton.Input
                    data-testid="menu-skeleton-input"
                    active={isLoading}
                    style={style.menuLoading(colorPrimary, isLoading, props.isSmall)}
                />
            );
        };
        const loadingItems = Array.from({length: 2}, (_, index) => <SkeletonItem key={index} />);

        return <>{loadingItems}</>;
    };

    const initialized = async () => {
        setIsLoading(true);
        setTimeout(() => {
            setItems(paths(authentication?.paths));
            setIsLoading(false);
        }, 2000);
    };

    React.useEffect(() => {
        initialized();
    }, []);

    const handleNavigate = (key: string) => {
        props.setSelectItem(key);
        navigate(key);
    };

    const Menu = () => {
        return (
            <>
                {items.length > 0 &&
                    items?.map((item: any) => (
                        <span
                            key={item.key}
                            tabIndex={0}
                            style={{
                                marginLeft: '10%',
                                display: 'block',
                                opacity: item.key === props.selectItem ? 1 : 0.5,
                                cursor: 'pointer',
                                color: 'white',
                                fontWeight: 500,
                                fontSize: 24,
                                lineHeight: 2,
                            }}
                            onClick={() => handleNavigate(item.key)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleNavigate(item.key);
                                }
                            }}
                        >
                            {item.label}
                        </span>
                    ))}
            </>
        );
    };

    return (
        <div style={{marginTop: props.isSmall ? '30%' : '50%'}}>
            {isLoading ? <Loading /> : <Menu />}
        </div>
    );
};

export default Menu;
