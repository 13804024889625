import React from 'react';
import {MenuOutlined} from '@ant-design/icons';
import {Layout as AntLayout, theme, Button} from 'antd';

import style from './style';

interface IHeader {
    isSmall: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}

const Header: React.FC<IHeader> = (props: IHeader) => {
    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (
        <AntLayout.Header style={style.header(colorPrimary, props.isSmall)}>
            <div style={style.headerContainer}>
                {props.isSmall && (
                    <Button
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={() => props.setOpen(!props.open)}
                        style={style.buttonMenu}
                    />
                )}
            </div>
        </AntLayout.Header>
    );
};

export default Header;
