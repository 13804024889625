import {Form as AntForm} from 'antd';
import style from './style';
import {Typography} from 'antd';
const {Text} = Typography;

interface ItemInputValueProps {
    valuePropName?: string;
    onClick?: () => void;
}

const LinkForgotPassword = (props: ItemInputValueProps) => {
    return (
        <AntForm.Item
            name={props?.valuePropName ?? 'forgot-password'}
            valuePropName={props?.valuePropName ?? 'forgot-password'}
            noStyle
        >
            <Text underline data-testid="input-value" style={style.link} onClick={props?.onClick}>
                Esqueceu a senha?
            </Text>
        </AntForm.Item>
    );
};

export {LinkForgotPassword};
