import { AxiosInstance } from "axios";
import { CustomException } from '@core/modules/common/infra/error';
import { httpMessage } from '@core/modules/common/infra/message';
import { ProcedureGateway } from "../domain/procedure.gateway";
import MockAdapter from "axios-mock-adapter";
import { ICompany, IHealthnsurance, IScheduleGeneralItens, NationalityTypeForInterface, TRadio, TSelect } from "../domain/procedure.entities";
import { TMedicalProcedures } from "../domain/procedure.entities";
import { procedureTypes, reasonCancelSchedule } from "../__mocks__/procedure";
export class ProcedureHttpGateway implements ProcedureGateway {
  constructor(private http: AxiosInstance, private mock: MockAdapter) { }


  async loadSearchGeneral(id?: string): Promise<IScheduleGeneralItens> {
    const path = `/customer-portal/v1/schedule/${id}`.replace("undefined", "");

    return await this.http.get(path)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        if (error?.status !== 409) {
          throw new CustomException(error?.response?.data);
        }
        throw new CustomException(httpMessage.getAllError('os agendamentos'));
      });
  }

  async createProcedure(params: any): Promise<any> {
    const path = `/customer-portal/v1/schedule/`;

    return this.http
      .post(path, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error?.status !== 409) {
          throw new CustomException(error?.response?.data);
        }
        throw new CustomException(httpMessage.addResponseEmpty('o agendamento'));
      });
  }

  async updateProcedure(params: any): Promise<any> {
    const path = `/customer-portal/v1/schedule/`;

    return this.http
      .put(path, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error?.status !== 409) {
          throw new CustomException(error?.response?.data);
        }
        throw new CustomException(httpMessage.addResponseEmpty('o agendamento'));
      });
  }

  async getAllMedicalProcedure(searchGeneral: IScheduleGeneralItens): Promise<TMedicalProcedures[]> {
    //const { data } = getAllMedicalProcedure(); // mock

    const data = searchGeneral.procedures;

    return data.map((item) => {
      return {
        id: item.id,
        code: item.tuss_code,
        name: item.name,
        isPrincipal: false,
        estimatedTimeSecond: item?.estimated_time ?? 3600
      };
    });
  }

  async getAllChargeMethods(searchGeneral: IScheduleGeneralItens): Promise<TSelect[]> {
    //const { data } = chargeMethods(); //mock

    const data = searchGeneral.payment_types;

    return data.map((item) => {
      return {
        label: item.name,
        value: String(item.id),
      };
    }
    );
  }

  async getAllProcedureTypes(): Promise<TRadio[]> {
    const path = `/procedure-types`;

    const { data } = procedureTypes();

    return data.map((item) => {
      return {
        title: item.title,
        value: item.value,
      };
    });
  }

  async getAllProcedureLocation(searchGeneral: IScheduleGeneralItens): Promise<TSelect[]> {
    // const { data } = procedureLocation();  //mock

    const data = searchGeneral.units;

    return data.map((item) => {
      return {
        label: item.name,
        value: String(item.id),
      };
    });
  }

  async getGenres(searchGeneral: IScheduleGeneralItens): Promise<TSelect[]> {
    // const { data } = genres();  //mock

    const data = searchGeneral.gender;

    return data.map((item) => {
      return {
        label: item.text,
        value: item.value,
      };
    });
  }

  async getRelationships(searchGeneral: IScheduleGeneralItens): Promise<TSelect[]> {
    // const { data } = relationships();  // mock

    const data = searchGeneral.kinship;

    return data.map((item) => {
      return {
        label: item.text,
        value: item.value,
      };
    });
  }

  async getDoctors(searchGeneral: IScheduleGeneralItens): Promise<TSelect[]> {

    const data = searchGeneral.surgeons;

    return data.map((item) => {
      return {
        label: String(item?.name),
        value: String(item.id),
      };
    });
  }

  async getNationality(searchGeneral: IScheduleGeneralItens): Promise<NationalityTypeForInterface[]> {

    const data = searchGeneral.nationality;

    return data
  }

  async getReasonCancelSchedule(): Promise<TSelect[]> {

    const { data } = reasonCancelSchedule();

    return data.map((item) => {
      return {
        label: item.label,
        value: item.value
      }
    });
  }

  async getData(): Promise<any> {

    const { data } = reasonCancelSchedule();

    return data.map((item) => {
      return {
        label: item.label,
        value: item.value
      }
    });
  }

  async getCompanies(): Promise<TSelect[]> {
    const path = `/customer-portal/v1/health/company`;

    return this.http
      .get(path)
      .then((res) => {
        return res.data.map((item: ICompany) => {
          return {
            label: item.name,
            value: item.id,
          }
        });
      })
      .catch((error) => {
        if (error?.status !== 409) {
          throw new CustomException(error?.response?.data);
        }
        throw new CustomException(httpMessage.addResponseEmpty('O convênio'));
      });
  }

  async getPlans(id: string): Promise<TSelect[]> {
    const path = `/customer-portal/v1/health/plan/${id}`;

    return this.http
      .get(path)
      .then((res) => {
        return res.data.map((item: IHealthnsurance) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      })
      .catch((error) => {
        if (error?.status !== 409) {
          throw new CustomException(error?.response?.data);
        }
        throw new CustomException(httpMessage.addResponseEmpty('O plano do convênio'));
      });
  }
}
