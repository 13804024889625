import { Typography } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { TMedicalProcedures } from '@/@core/modules/procedure/domain/procedure.entities';
import Procedures from '@/@presentation/modules/procedure/components/procedures';

interface IProps {
  selectedProcedures: TMedicalProcedures[];
  handleProcedurePrincipal: (code: string) => void;
  handleRemoveProcedure: (code: string) => void;
  isConfirm: boolean;
}

export const SelectedProcedures: React.FC<IProps> = (props) => {
  return (
    <div>
      <Typography style={styles.typography}>Procedimentos selecionados</Typography>

      {props?.selectedProcedures?.length <= 0 && (<Typography.Text>Nenhum procedimento selecionado</Typography.Text>)}

      {props.selectedProcedures.map((procedure) => (
        <Procedures
          key={procedure?.id}
          code={procedure?.code}
          name={procedure?.name}
          isPrincipal={procedure?.isPrincipal}
          onChange={() => props.handleProcedurePrincipal(procedure?.code)}
          onRemove={props.handleRemoveProcedure}
          isConfirm={props?.isConfirm}
        />
      ))}
    </div>
  )
};