import { useItemsContext } from "@/@presentation/contexts/schedulesContext";
import { Alert, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from '@presentation/components/common';

const ButtonsBar = (props: {
    selectedItems: any[]
    nfNumber: number
}) => {

    const navigate = useNavigate()
    const { setItems, setOrigin } = useItemsContext();

    const showPaymentProcess = () => {

        const itemsWithZeroTotal = props.selectedItems.filter(item => {
            return item?.total.replace(/\D/g, '') === '000';
        })

        if (props.selectedItems.filter(item => item?.schedulePaymentTypeId === 5).length > 0) {
            Notification.error
            ({
                message: 'Não é possível realizar o Pagamento para items em Cortesia!',
                duration: 3,
            })

            return
        }

        if (props.selectedItems.length > 1) {
            Notification.error
            ({
                message: 'Não é possível realizar o Pagamento em lote!',
                duration: 3,
            })

            return
        }

        if (itemsWithZeroTotal.length > 0) {
            Notification.error({
                message: 'Não é possível realizar o Pagamento com valor zerado!',
                duration: 3,
            });
            return;
        }

        if (props.selectedItems.filter(item => item?.statusBilling === 'paid').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível realizar o Pagamento de Itens já pagos!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.status === 'CANCELADO').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível realizar o Pagamento de Item Cancelado!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.statusBilling === 'pending').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível realizar o Pagamento de Itens com pagamentos pendentes!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.length > 1 ){

            const antecipado = props.selectedItems.filter(item => item.checkIn === null ).length

            if ( antecipado >= 1 ){

                Notification.error
                ({
                    message: 'Não é possível realizar o Pagamento Antecipado de mais de 1 item!',
                    duration: 3,
                })

                return
            }

        }

        if (props.selectedItems.some(item => item.receiverName !== props.selectedItems[0].receiverName)) {

            Notification.error
                ({
                    message: 'Existem itens selecionados com tomadores diferentes!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.length > 0) {
            setOrigin('payment')
            setItems(props.selectedItems);
            navigate(`/paymentprocess`);
        }
    }

    const showNfProcess = () => {

        const itemsWithZeroTotal = props.selectedItems.filter(item => {
            return item?.total.replace(/\D/g, '') === '000';
        })

        if (itemsWithZeroTotal.length > 0) {
            Notification.error({
                message: 'Não é possível solicitar NF com valor zerado!',
                duration: 3,
            });
            return;
        }

        if (props.selectedItems.filter(item => item?.schedulePaymentTypeId === 5).length > 0) {
            Notification.error
            ({
                message: 'Não é possível emitir a NF para items em Cortesia!',
                duration: 3,
            })

            return
        }

        const procedimentosEmAberto = props.selectedItems.filter(item => item.checkIn === null || item.checkOut === null ).length

        if ( procedimentosEmAberto > 0 ){
            Notification.error
            ({
                message: 'Não é possível emitir a NF com procedimentos em aberto!',
                duration: 5,
            })

        return
        }
        
        if (props.selectedItems.some(item => item.receiverName !== props.selectedItems[0].receiverName)) {

            Notification.error
                ({
                    message: 'Existem itens selecionados com tomadores diferentes!',
                    duration: 5,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.status === 'CANCELADO').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para Item Cancelado!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.advancePayment === true && item?.statusBilling !== 'canceled' ).length > 0) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para Item com Pagamento Antecipado!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.statusInvoice === 'Solicitado' ).length > 0) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para itens já enviados!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.length > 0) {
            setOrigin('payment')
            setItems(props.selectedItems);
            navigate(`/nfprocess`);
        }
    }

    return (
        <div style={{ marginTop: 50, right: 80, padding: 10 }}>
            <div style={{ textAlign: 'right' }}>
                {/*
                <Button
                    style={{
                        borderRadius: 20,
                        color: '#FFFFFF',
                        backgroundColor: props.nfNumber !== 0 ? '#FF6D6D' : '#BABBAF',
                        border: '1px solid #FFFFFF',
                        boxShadow: props.nfNumber !== 0 ? '0 0 0 5px #FF6D6D' : '0 0 0 5px #BABBAF',
                        width: 200,
                        marginRight: 20
                    }}
                >
                    Cancelar NF
                </Button>
                */}

                <Button
                    style={{
                        width: 200,
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        color: props.selectedItems.length > 0 ? '#054B7C' : '#BABBAF',
                        border: props.selectedItems.length > 0 ? '1px solid #054B7C' : '1px solid #BABBAF',
                        marginRight: '10px',
                        height: 40
                    }}
                    onClick={() => showPaymentProcess()}
                >
                    Pagamento
                </Button>

                <Button
                    style={{
                        width: 200,
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        color: props.selectedItems.length > 0 ? '#054B7C' : '#BABBAF',
                        border: props.selectedItems.length > 0 ? '1px solid #054B7C' : '1px solid #BABBAF',
                        height: 40
                    }}
                    onClick={() => showNfProcess()}
                >
                    Emitir nota fiscal
                </Button>
            </div>
        </div>
    )
}

export default ButtonsBar
