import React from 'react';
import {theme, Skeleton} from 'antd';
import style from './style';
import {useLoading} from '@presentation/contexts/loading';

interface IFooter {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: React.FC<IFooter> = (props: IFooter) => {
    const {isLoading} = useLoading();
    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (
        <div
            data-testid="footer-div"
            style={style.container(colorPrimary)}
            onClick={() => props.setCollapsed(!props.collapsed)}
        >
            {isLoading ? (
                <Skeleton.Input
                    data-testid="footer-skeleton-input"
                    active={isLoading}
                    style={style.imgLoading(colorPrimary, isLoading)}
                />
            ) : (
                <img
                    data-testid="footer-img"
                    alt="iconHide"
                    style={style.img(props.collapsed)}
                    src="/takaoka-icon-transparent.svg"
                />
            )}
        </div>
    );
};

export default Footer;
