import React from 'react';
import {Avatar, theme, Skeleton, Badge} from 'antd';
import {useAuthentication} from '@presentation/modules/authentication/contexts/authentication';
import notAuthentication from '@presentation/modules/route/notAuthenticated';
import {auth} from '@core/modules/authentication/infra/container.registry';
import word from '@core/modules/common/infra/word';
import style from './style';

const Profile: React.FC = () => {
    const {user, loading} = useAuthentication();
    const [isLoading, setIsLoading] = React.useState(false);

    const translateUserType = (type) => {
        const types = {
            'BACKOFFICE': 'Backoffice',
            'SECRETARY': 'Secretária',
            'ANESTHETIST': 'Anestesista',
            'SURGEON': 'Cirurgião',
            'PATIENT': 'Paciente'
        };
        return types[type] || type;
    };

    const {
        token: {blue1},
    } = theme.useToken();

    const handleLogout = async () => {
        setIsLoading(true);
        await auth.logout.execute();

        setTimeout(() => {
            window.location.replace(notAuthentication.defaultRoute.key);
            setIsLoading(false);
        }, 1000);
    };

    return (
        <>
            {isLoading || loading ? (
                <Skeleton.Avatar
                    style={style.avatar('')}
                    active={isLoading}
                    size={64}
                    shape="square"
                />
            ) : (
                <div style={style.container}>
                    <Badge dot style={{height: 18, width: 18, borderColor: 'red'}}>
                        <Avatar style={style.avatar(blue1)} size={64} shape="square">
                            {word.defaultName(word.getFirstTwoLetters(user?.name ?? ''))}
                        </Avatar>
                    </Badge>
                    <span style={style.name}>
                        {word.defaultName(word.getFirstName(user?.name))}
                    </span>
                    <span style={style.type}>{translateUserType(user?.type)}</span>
                    <span style={style.email}>{user?.email}</span>
                    <span style={style.type} onClick={() => handleLogout()}>
                        Sair
                    </span>
                </div>
            )}
        </>
    );
};

export default Profile;
