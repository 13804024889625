import customer from '@/@core/modules/customer/infra/container.registry';
import { UpdateCustomerInterface } from '@/@presentation/modules/schedule/pages/home/receiver';

export async function CreateCustomerApi(data: UpdateCustomerInterface){

  try{
    const res = await customer.create.execute(data)
    return res
  }catch{
    return 0
  }

}

export async function UpdateCustomerApi(data: UpdateCustomerInterface){

  try{
    const res = await customer.update.execute(data)
    return res
  }catch{
    return 0
  }

}