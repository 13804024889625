import {AuthenticationProvider} from '../authentication/contexts/authentication';
import {LoadingProvider} from '@presentation/contexts/loading';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {plugin, observability} from '../../../config/observability';
import Security from '../../../config/security';
import Routes from './routes';
import { ProcedureProvider } from '../procedure/context/procedure';
import { ItemsProvider } from '@/@presentation/contexts/schedulesContext';

const Root = () => {
    Security();
    observability();
    return (
        <AuthenticationProvider>
            <LoadingProvider>
                <AppInsightsContext.Provider value={plugin}>

                    <ItemsProvider>
                        <ProcedureProvider>
                            <Routes />
                        </ProcedureProvider>
                    </ItemsProvider>

                </AppInsightsContext.Provider>
            </LoadingProvider>
        </AuthenticationProvider>
    );
};

export default Root;
