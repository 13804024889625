import React from 'react';
import {Button, Flex, Form, Row, Col} from 'antd';
import {useNavigate} from 'react-router-dom';
import {InputEmail, InputPassword, validateMessages} from '../../../../components/form';

import {TypeRequestAccessParams} from '@core/modules/authentication/domain/requestAccess.entities';
import {useAuthentication} from '@presentation/modules/authentication/contexts/authentication';
import {ForgotPassword} from '../../components/forgotPassword';
import {ComponentTermsOfUseAndPrivacyPolicies} from '../../components/termsOfUseAndPrivacyPolicies';
import {auth} from '@core/modules/authentication/infra/container.registry';
import authentication from '@presentation/modules/route/authentication';

import style from './style';

const Login = () => {
    const navigate = useNavigate();
    const {signIn} = useAuthentication();
    const [loading, setLoading] = React.useState(false);
    const [visibleTermsOfUseAndPrivacyPolicies, setVisibleTermsOfUseAndPrivacyPolicies] =
        React.useState(false);

    const initialValuesDev = {
        // email: 'thiagogladstone@gmail.com',
        // password: '123456789',
    };

    const handleLogin = async (values: TypeRequestAccessParams) => {
        try {
            setLoading(true);
            const isLogin = await signIn(values);

            if (isLogin?.access_token) {
                const acceptLastTerm = await auth.acceptLastTerm.execute();
                if (!acceptLastTerm) {
                    setVisibleTermsOfUseAndPrivacyPolicies(true);
                } else {
                    setTimeout(async () => {
                        navigate(authentication.defaultRoute.key);
                        setLoading(false);
                    }, 1500);
                }
            }
        } finally {
            setTimeout(async () => {
                setLoading(false);
            }, 4000);
        }
    };
    return (
        <div style={style.div}>
            {visibleTermsOfUseAndPrivacyPolicies && (
                <ComponentTermsOfUseAndPrivacyPolicies
                    visible={visibleTermsOfUseAndPrivacyPolicies}
                    setVisible={setVisibleTermsOfUseAndPrivacyPolicies}
                />
            )}

            <Row style={style.row}>
                <Col xs={{span: 0}} sm={{span: 0}} md={{span: 12}} style={style.col}>
                    <img alt="background" src="/background.svg" style={style.img} />
                </Col>
                <Col
                    xs={{span: 24}}
                    sm={{span: 24}}
                    md={{span: 12}}
                    style={{
                        ...style.col,
                        ...style.colSecond,
                    }}
                >
                    <div style={style.card}>
                        <img
                            alt="tk"
                            src="/tk.svg"
                            style={{margin: 'auto'}}
                            height={56}
                            width={176}
                        />
                        <span style={style.cardTitle}>BEM-VINDO(A) AO PORTAL DO CIRURGIÃO</span>
                        <span style={style.cardSubTitle}>
                            Nossa parceria cada vez mais simplificada!
                        </span>
                        <span
                            style={{
                                ...style.cardSubTitle,
                                marginTop: '3.5%',
                            }}
                        >
                            Acesse suas notas fiscais e, em breve, agende procedimentos com
                            facilidade.
                        </span>

                        <span style={style.formTitle}>Faça o seu login</span>
                        <Form
                            name="normal_login"
                            className="login-form"
                            labelCol={{span: 12}}
                            layout="vertical"
                            validateMessages={validateMessages}
                            initialValues={initialValuesDev}
                            onFinish={handleLogin}
                            autoComplete="off"
                            style={{
                                marginTop: '1%',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <InputEmail label="" placeholder="LOGIN" required={true} />
                            <InputPassword label="" placeholder="SENHA" required={true} />

                            <Flex justify="center" style={style.cardFormFlex}>
                                <Button
                                    htmlType="submit"
                                    className="login-form-button"
                                    loading={loading}
                                    style={{
                                        borderRadius: 20,
                                        width: '50%',
                                        color: '#FFFFFF',
                                        backgroundColor: '#43AB66',
                                        border: '2px solid #FFFFFF',
                                        boxShadow: '0 0 0 5px #43AB66',
                                        marginBottom: '5%',
                                    }}
                                >
                                    Entrar
                                </Button>
                            </Flex>

                            <ForgotPassword />
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
