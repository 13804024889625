import Home from './pages/home';
import NfProcess from './pages/nfProcess';
import PaymentProcess from './pages/paymentProcess';
import ScheduleList from './pages/schedule';
import View from './pages/view';

import {ENUM_PERMISSIONS} from '@presentation/modules/route/helpers';

export const formattedRoute = (route: string, value?: any) => {
    return route.replace('/:key', `/${value}`).replace('/:id', `/${value}`);
};

export enum routes {
    HOME = "/home",
    SCHEDULE = "/schedule",
    SCHEDULE_VIEW = '/schedule/:id',
}

export const permissions = [
    ENUM_PERMISSIONS.admin,
    ENUM_PERMISSIONS.secretary,
    ENUM_PERMISSIONS.surgeon,
];

export const paths = [
    {
        label: 'Financeiro',
        key: routes.HOME,
        icon: null,
        element: <Home />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: routes.SCHEDULE_VIEW,
        icon: null,
        element: <View />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: '/paymentprocess',
        icon: null,
        element: <PaymentProcess />,
        permission: permissions,
        children: null,
    },
    {
        label: '',
        key: '/nfprocess',
        icon: null,
        element: <NfProcess />,
        permission: permissions,
        children: null,
    },
    {
        label: 'Agendamento',
        key: routes.SCHEDULE,
        icon: null,
        element: <ScheduleList />,
        permission: permissions,
        children: null,
    }
];
