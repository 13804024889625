const avatar = (bgColor: string) => {
    return {
        backgroundColor: bgColor,
        cursor: 'pointer',
        color: 'black',
        borderRadius: 12,
        marginBottom: 20,
    };
};

const container = {
    margin: '10%',
    marginTop: '34%',
    display: 'grid',
    cursor: 'pointer',
};

const name = {
    color: 'white',
    fontWeight: 500,
    fontSize: 22,
    lineHeight: 1.5,
    cursor: 'pointer',
};

const type = {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: 1.5,
    color: 'white',
    cursor: 'pointer',
};

const email = {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.5,
    color: 'white',
};

const content = {cursor: 'pointer'};

export default {
    avatar,
    content,
    container,
    name,
    type,
    email,
};
