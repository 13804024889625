import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button as ButtonAntd, Col, Form, Layout, Row, Space, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LoadingOutlined } from '@ant-design/icons';
import Infos from './components/infos';
import Details from './components/details';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
import {formattedRoute} from '../../routes';
import {routes} from "../../../procedure/routes";
import schedule from '@/@core/modules/schedule/infra/container.registry';
import { Schedule } from '@/@core/modules/schedule/domain/schedule.entities';
import ConfirmModal from './components/modal/confirm';
import RescheduleModal from './components/modal/reschedule';
import CancelModal from './components/modal/cancel';
import { Button } from '@/@presentation/components/form/Button';
import moment from 'moment';
import procedure from '@/@core/modules/procedure/infra/container.registry';
import { useProcedure } from '@/@presentation/modules/procedure/context/procedure';
import { Notification } from '@/@presentation/components/common';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import { auth } from '@/@core/modules/authentication/infra/container.registry';


const View = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { origin } = useItemsContext()
    
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<Schedule | undefined>();
    const canEdit = location.state?.canEdit || data?.props.status === 'pending';
    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const [openRescheduleModal, setOpenRescheduleModal] = React.useState(false);
    const [openCancelModal, setOpenCancelModal] = React.useState(false);

    const [isBackoffice, setIsBackoffice] = React.useState(false);

    const getUser = async () => {
        const userInfos = await auth.userInfos.execute();
        setIsBackoffice(userInfos?.props.type === 'BACKOFFICE');
    }

    const { states, actions } = useProcedure();

    const onConfirm = async (isConfirm: boolean) => {
        if (isConfirm) {
            await schedule.confirm.execute([String(id)])
            navigate(0)
        } else {
            navigate(-1)
        }
    }

    const onReschedule = async (isReschedule: boolean, date?: string, time?: string) => {
        if (!isReschedule) {
            setOpenRescheduleModal(false)
            return;
        }

        const dateMoment = moment(date, 'DD/MM/YYYY');
        const timeMoment = moment(time, 'HH:mm');
        const formatedDate = dateMoment.format('YYYY-MM-DD') + ' ' + timeMoment.format('HH:mm:ss.SSS ZZ');
        const confirmDate = procedure.create.scheduleDateValidationOnCreate(new Date(formatedDate))

        if (confirmDate.isError) {
            Notification.error({
                message: confirmDate.text,
                duration: 4,
              })
              return;
        }

        const data = states.data;
        data.schedule.schedule_date = formatedDate
        try {
            // Aguarda o procedimento de atualização ser concluído
            await procedure.update.execute(data);
    
            // Após a conclusão do update, redireciona para a página atualizada
            navigate(0);
        } catch (error) {
            // Se houver um erro, você pode exibir uma notificação de erro
            Notification.error({
                message: 'Erro ao reagendar o procedimento.',
                duration: 4,
            });
        }
        //procedure.update.execute(data)
        //navigate(0)
    }

    const onCancel = async (reason: string, other = '') => {
        const text = other.length > 0 ? reason + ' - ' + other : reason
        await schedule.cancel.execute(String(id), text)
        navigate(0)
    }

    async function loadData() {
        if (id && id !== undefined) {
            setLoading(true);
            const result = await schedule.get.execute({
                id: id,
            });

            setData(result);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
        getUser();
        actions.handleInitLoadSearchGeneral(id);
    }, [id]);

    return (
        <>
            <ConfirmModal onConfirm={onConfirm} open={openConfirmModal} />
            {states.data?.schedule?.schedule_date && <RescheduleModal initialDate={String(states.data?.schedule?.schedule_date)} onReschedule={onReschedule} open={openRescheduleModal} />}
            <CancelModal onCancel={onCancel} open={openCancelModal} />
            <Layout
                data-testid="layout-schedule-view"
                style={{
                    height: '80vh',
                    backgroundColor: 'white',
                }}
            >
                <Content className="site-layout-content" >
                    <div
                        style={{
                            justifyContent: 'flex-end',
                            width: '100%',
                            display: origin === '' ? 'flex' : 'none'
                        }}
                    >
                        <Button
                            text='Confirmar'
                            disabled={("confirmed" === data?.props.status || 'canceled' === data?.props.status || canEdit === false) && !isBackoffice}
                            onClick={() => setOpenConfirmModal(true)}
                            nativeProps={{style: {
                                marginTop: 5,
                            }}}
                        />
                        <Button
                            text='Reagendar'
                            onClick={() => setOpenRescheduleModal(true)}
                            disabled={('canceled' === data?.props.status || canEdit === false) && !isBackoffice}
                            nativeProps={{
                                style: {
                                    color: '#195986',
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    border: '2px solid #195986',
                                    height: 40,
                                    marginLeft: 10,
                                    marginRight: 5,
                                    boxShadow: 'none',
                                },
                            }}
                        />
                        <Button
                            text='Editar'
                            disabled={('canceled' === data?.props.status || canEdit === false) && !isBackoffice}
                            onClick={() => navigate(formattedRoute(routes.PROCEDURE_EDIT, data?.props.id))}
                            nativeProps={{
                                style: {
                                    color: '#195986',
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    border: '2px solid #195986',
                                    height: 40,
                                    marginLeft: 5,
                                    marginRight: 10,
                                    boxShadow: 'none',
                                },
                            }}
                        />
                        <Button
                            text='Cancelar'
                            disabled={('canceled' === data?.props.status || canEdit === false) && !isBackoffice}
                            onClick={() => setOpenCancelModal(true)}
                            nativeProps={{
                                style: {
                                    backgroundColor: ('canceled' === data?.props.status || canEdit === false) && !isBackoffice ? '#808080' : '#FF6D6D',
                                    boxShadow:`0 0 0 5px ${('canceled' === data?.props.status || canEdit === false) && !isBackoffice ? '#808080' : '#FF6D6D'}`,
                                    marginTop: 5
                                },
                            }}
                        />
                    </div>
                    <Content className="pd-24">
                        <Spin spinning={loading} indicator={antIcon} tip="Carregando..."></Spin>
                        <Row justify="start">
                            <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                                <Space size="small" style={{ display: 'flex' }}></Space>
                            </Col>
                            <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                                <Form.Item></Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Infos data={data} />
                            <Details data={data} />
                        </Row>
                        <ButtonAntd
                            about=""
                            type="link"
                            onClick={() => navigate(-1)}
                            ghost
                            size="large"
                            style={{ marginBottom: 30, fontSize: 20 }}
                        >
                            Voltar
                        </ButtonAntd>
                    </Content>
                </Content>
            </Layout>
        </>
    );
};

export default View;
