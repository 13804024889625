import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Form, Layout, Row, Space, Spin, Input, Select, Divider} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {LoadingOutlined} from '@ant-design/icons';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import moment from 'moment';
import InputMask from 'react-input-mask';
import CheckCep from '@/services/viaCep';
import {Notification} from '@presentation/components/common';
import { UpdateCustomerInterface } from '../home/receiver';
import customer from '@/@core/modules/customer/infra/container.registry';
import { BillInterface } from '@/@core/modules/bill/domain/bill.entities';
import bill from '@/@core/modules/bill/infra/container.registry';
import cataGadp from '@/@core/modules/validateCataGadp/infra/container.registry';
import { TProcedureUpdate } from '@/@core/modules/schedule/domain/schedule.entities';
import schedule from '@/@core/modules/schedule/infra/container.registry';

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

interface paymentOptionsInterface {
    label: string,
    value: string
}

const PaymentProcess = () => {

    const navigate = useNavigate();
    const { items, setItems } = useItemsContext();

    const [loading, setLoading] = React.useState(false);

    const [pagador, setPagador] = useState('')
    const [dtCirurgia, setDtCirurgia] = useState<string>('')
    const [paymentType, setPaymentType] = useState('')
    const [intallentNumber, setIntallentNumber] = useState(1)
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [address, setAddress] = useState('')
    const [numberHome, setNumberHome] = useState('')
    const [complement, setComplement] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [paymentCustomerId, setPaymentCustomerId] = useState(0)

    const [error, setError] = useState(false)
    const [stateError, setStateError] = useState(false);

    const validStateAbbreviations = [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA',
        'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN',
        'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
    ];

    const [paymentOptions, setPaymentOptions] = useState<paymentOptionsInterface[]>([])
    const [installmentsNumber, setInstallmensNumber] = useState<paymentOptionsInterface[]>([])

    async function validateCPF(strCPF: string): Promise<boolean> {
        strCPF = strCPF.replace(/\D/g, '')

        if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
            strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' ||
            strCPF === '88888888888' || strCPF === '99999999999' || strCPF.length !== 11) {
            return false;
        }

        let Soma = 0;
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }

        let Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            return false;
        }

        Soma = 0;
        for (let k = 1; k <= 10; k++) {
            Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k);
        }

        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            return false;
        }

        return true;
    }

    async function handlePayment() {

        setLoading(true)

        const  validadeCpf = await validateCPF(cpf)

        if ( validadeCpf === false ){

            Notification.error({
                message: 'O CPF informado não é valido!',
                duration: 3,
            });

            setLoading(false)

            return
        }

        if ( pagador.length === 0 || cep.length === 0 || address.length === 0 || email.length === 0 || cpf.length === 0 || paymentType.length === 0 ){

            setError(true)
            Notification.error({
                message: 'Por favor informar os campos obrigatórios!',
                duration: 5,
            });

            setLoading(false)

            return
        }else{

            let customerId = paymentCustomerId

            // Verificar CATA ou GADP
            const resVerify = await cataGadp.verify.execute(items[0].id)

            if ( paymentCustomerId === 0 || cpf !== items[0]?.cpf ){

                const addressRes = await CheckCep(cep)

                const newCustomer : UpdateCustomerInterface = {
                    scheduleId: +items[0].id,
                    name: pagador,
                    email: email,
                    registry_code: cpf,
                    code: cpf,
                    address: {
                        street: address,
                        number: numberHome,
                        additional_details: complement,
                        zipcode: cep,
                        neighborhood: addressRes?.bairro || neighborhood,
                        city: addressRes?.localidade || city,
                        state: addressRes?.uf || state,
                    }
                }

                customerId = await customer.create.execute(newCustomer)
                setPaymentCustomerId(customerId)

            }

            let advancePaymentStatus = false

            if ( items.length > 1 ){
                advancePaymentStatus = false
            }else{
                const checkin = items[0]?.checkIn ?? null
                const checkout = items[0]?.checkOut?? null

                if ( checkin === null && checkout === null ){
                    advancePaymentStatus = true
                }
            }

            const dataUpdate: TProcedureUpdate = {
                id: items[0]?.id,
                amount: formattedTotalValue.replace('.','').replace(',','.'),
                updatedBySecretary: false
            }

            const resUpdatePayment = await schedule.update.execute(dataUpdate)

            // -------------------------------------------------------------------

            const newBill : BillInterface = {
                paymente_customer_id: customerId,
                installments: intallentNumber,
                method_code: paymentType,
                schedules_ids:  items?.map((item) => item?.id) || [],
                advance_payment: advancePaymentStatus,
                payment_billing_id: items[0]?.paymentBillingId ?? 0,
                typeCG: resVerify === 3 ? 'cata' : 'gadp'
            }

            const res = await bill.create.execute(newBill)

            if ( res ){

                Notification.success({
                    message: 'Pagamento gerado e encaminhado com sucesso!',
                    duration: 2,
                });

                setLoading(false)

                navigate('/home')

            }else{

                Notification.error({
                    message: 'Não foi possível solicitar o pagamento nesse momento! Tente Novamente!',
                    duration: 2,
                });

                setLoading(false)
            }

            setError(false)

        }

    }

    useEffect(()=>{

        setDtCirurgia(items[0]?.scheduleDate ? moment(items[0]?.scheduleDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');

        if ( items[0].installments_number === 0 || items[0].installments_number === undefined ){

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' }
            ])

        }else{

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' },
                { label: 'CARTÃO DE CRÉDITO', value: 'credit_card' }
            ])

            const newInstallments = [];
            for (let i = 1; i <= items[0].installments_number; i++) {
                newInstallments.push({ label: `${i}`, value: `${i}` });
            }

            setInstallmensNumber(newInstallments)
        }

        setPagador(items[0]?.paymentCustomer?.name || 'Não informado')
        setEmail(items[0]?.paymentCustomer?.email ?? '')
        setCpf(items[0]?.paymentCustomer?.registry_code ?? '')

        setPaymentCustomerId(items[0]?.paymentCustomer?.id || 0)

        if ( items[0].paymentCustomer?.address ){

            setCep(items[0]?.paymentCustomer?.address?.zipcode ?? '')
            setAddress(items[0]?.paymentCustomer?.address?.street ?? '')
            setNumberHome(items[0]?.paymentCustomer?.address?.number ?? '')

        }else{

            setCep(items[0]?.paymentCustomer?.zipcode ?? '')
            setAddress(items[0]?.paymentCustomer?.street ?? '')
            setNumberHome(items[0]?.paymentCustomer?.number ?? '')
        }

        setPaymentType(items[0]?.methodCode ?? '')
        setIntallentNumber(items[0]?.paymentInstallmentsNumber ?? 1)

    },[items])

    const handleDateChange = (date) => {
        setDtCirurgia(date);
    }

    async function handleZipCode(zipcode: string){

        if ( zipcode.length > 7 ){

            const address = await CheckCep(zipcode)
            setAddress(address.logradouro)
            setNeighborhood(address.bairro)
            setCity(address.localidade)
            setState(address.uf)
        }

    }

    const totalValue = items.reduce((acc, item) => {

        if (!item.total) {
            return acc;
        }

        const totalWithoutCurrency = item.total.replace(/[^\d,]/g, '');
        const totalWithDot = totalWithoutCurrency.replace(',', '.');

        const parsedTotal = parseFloat(totalWithDot);
        return isNaN(parsedTotal) ? acc : acc + parsedTotal;

    }, 0);

    const formattedTotalValue = totalValue?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleStateChange = (value: string) => {
        setState(value.toUpperCase());
        setStateError(!validStateAbbreviations.includes(value.toUpperCase())); // Verifica se a abreviação é válida
    }

    return (
        <Layout
            data-testid="layout-schedule-view"
            style={{
                height: '80vh',
                backgroundColor: 'white',
            }}
        >
            <Content className="site-layout-content">
                <Content className="pd-24">
                    <Spin spinning={loading} indicator={antIcon} tip="Carregando..."></Spin>

                    <Row justify="start">
                        <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                            <Space size="small" style={{display: 'flex'}}></Space>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                            <Form.Item></Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 60 }}>
                        <p style={{
                            color: '#262A41',
                            fontSize: 34,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '106%',
                            marginTop: 14,
                            marginBottom: 18,
                            letterSpacing: 0.73,
                        }}>Pagamento</p>
                    </Row>
                    <Row>
                        <p style={{
                            color: '#262A41',
                            fontSize: 26,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '106%',
                            marginBottom: 14,
                            letterSpacing: 0.73,
                        }}>Formas de pagamento</p>
                    </Row>


                    <Form layout="vertical" >

                        <Row style={{width: '90%', marginLeft: '5%', marginBottom: -20}}>

                            <Col span={15} style={{padding: 20}}>
                                <Form.Item
                                    label="Nome do pagador*"
                                    // help={error && pagador.length === 0 ? 'Por favor, informe o nome do pagador' : null}
                                >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Informe o nome do pagador"
                                        onChange={(e)=>setPagador(e.target.value)}
                                        value={pagador}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} style={{padding: 20}}>
                                <Form.Item label="Data da cirurgia*" >
                                    <Input
                                        type='Date'
                                        style={{
                                            height: 40,
                                            borderRadius: 20,
                                            width: '100%',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Selecione Data"
                                        onChange={handleDateChange}
                                        value={dtCirurgia ? moment(dtCirurgia).format('YYYY-MM-DD') : ''}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%'}}>
                            <Col span={15} style={{paddingLeft: 20, paddingRight: 20}}>
                                <Form.Item
                                    label="Opção de pagamento"
                                    validateStatus={error ? 'error' : ''}
                                    // help={error ? 'Por favor, selecione uma forma de pagamento' : null}
                                >
                                    <Select
                                        style={{
                                            height: 40,
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Selecione a forma de pagamento"
                                        options={paymentOptions}
                                        onChange={(e)=>setPaymentType(e)}
                                        value={paymentType}
                                        disabled={items.filter((f)=>f.statusInvoice === 'Solicitado').length > 0 ? true : false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label="Valor" >
                                    <Input
                                        type='Text'
                                        style={{
                                            height: 40,
                                            borderRadius: 20,
                                            width: '100%',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        value={`R$ ${formattedTotalValue}`}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%'}}>
                            <Divider />
                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%', display: items[0].installments_number > 0 && paymentType === 'credit_card' ? 'flex' : 'none'}}>

                            <Row style={{width: '100%'}}>
                                <Col span={15} style={{paddingLeft: 20, paddingRight: 20}}>
                                    <Form.Item label="Quantidade de parcelas" >
                                        <Select
                                            style={{height: 40, width: 200}}
                                            placeholder="Selecione as parcelas"
                                            options={installmentsNumber}
                                            onChange={(e)=>setIntallentNumber(e)}
                                            disabled={items.filter((f)=>f.statusInvoice === 'Solicitado').length > 0 ? true : false}
                                            value={intallentNumber}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} style={{padding: 20}}></Col>
                            </Row>

                            <Divider />
                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%', marginTop: -10}}>
                            <Col span={4} style={{padding: 20}}>
                                <Form.Item label="CEP" >
                                <InputMask
                                    mask="99999-999"
                                    maskChar="_"
                                    onChange={(e)=>setCep(e.target.value)}
                                    onBlur={async (e) => await handleZipCode(e.target.value)}
                                    style={{
                                        borderRadius: 20,
                                        height: 40,
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        paddingLeft: 10,
                                        borderColor: error ? '#ff0000' : '#D9D9D9'
                                    }} 
                                    value={cep}
                                />
                                </Form.Item>
                            </Col>
                            <Col span={15} style={{padding: 20}}>
                                <Form.Item label="Rua" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Informe a rua"
                                        onChange={(e)=>setAddress(e.target.value)}
                                        value={address}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{padding: 20}}>
                                <Form.Item label="Número" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Número"
                                        onChange={(e)=>setNumberHome(e.target.value)}
                                        value={numberHome}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%', marginTop: -30}}>
                        <Col span={5} style={{padding: 20}}>
                                <Form.Item label="Complemento" >
                                    <Input
                                        style={{height: 40, textTransform: 'uppercase'}}
                                        placeholder="Informe o complemento"
                                        onChange={(e)=>setComplement(e.target.value)}
                                        value={complement}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} style={{padding: 20}}>
                                <Form.Item label="Bairro*" >
                                    <Input
                                        required
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Informe o bairro"
                                        onChange={(e)=>setNeighborhood(e.target.value)}
                                        value={neighborhood}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} style={{padding: 20}}>
                                <Form.Item label="Cidade*" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        required
                                        placeholder="Informe a cidade"
                                        onChange={(e)=>setCity(e.target.value)}
                                        value={city}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} style={{padding: 20}}>
                                <Form.Item label="Estado" >
                                    <Input
                                        style={{ height: 40, textTransform: 'uppercase', borderColor: stateError || error ? '#ff0000' : '#D9D9D9' }}
                                        placeholder="Informe o estado"
                                        onChange={(e) => handleStateChange(e.target.value)}
                                        value={state}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{width: '90%', marginLeft: '5%', marginTop: -30}}>
                            <Col span={19} style={{padding: 20}}>
                                <Form.Item label="E-mail" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'lowercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Informe o e-mail"
                                        onChange={(e)=>setEmail(e.target.value)}
                                        value={email}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} style={{padding: 20}}>
                                <Form.Item label="CPF" >
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar="_"
                                        onChange={(e) => setCpf(e.target.value)}
                                        style={{
                                            borderRadius: 20,
                                            height: 40,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            paddingLeft: 10,
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        value={cpf}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>

                    <Row style={{marginBottom: 20,
                        width: '90%', marginLeft: '5%', marginTop: 40}} className="justify-content-end">
                        <Button
                            style={{
                                width: 150,
                                borderRadius: '20px',
                                backgroundColor: 'white',
                                color: '#054B7C',
                                border: '1px solid #054B7C',
                                marginRight: '20px',
                                height: 39,
                                top: -2
                            }}
                            loading={loading}
                            onClick={() => navigate('/home')}
                        >
                            Voltar
                        </Button>

                        <Button
                            style={{
                                borderRadius: 20,
                                color: '#FFFFFF',
                                backgroundColor: '#43AB66',
                                border: '1px solid #FFFFFF',
                                boxShadow: '0 0 0 5px #43AB66',
                                width: 200,
                                marginLeft: 'auto' // Isso alinhará o botão à direita
                            }}
                            loading={loading}
                            onClick={() => handlePayment()}
                        >
                            Efetuar Pagamento
                        </Button>
                    </Row>

                </Content>
            </Content>
        </Layout>
    );
};

export default PaymentProcess;
