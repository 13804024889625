const menuLoading = (bgColor: string, isLoading: boolean, isSmall?: boolean) => {
    let ml;
    if (isSmall && isLoading) {
        ml = '2%';
    } else if (isLoading) {
        ml = '12%';
    } else {
        ml = 0;
    }
    return {
        backgroundColor: bgColor,
        marginTop: 10,
        marginLeft: ml,
    };
};

export default {
    menuLoading,
};
