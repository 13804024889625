import React, {useEffect, useState} from 'react';
import {Modal as AntModal, Typography, Button, Form, Row, Col, Input, Divider} from 'antd';
import {
    validateMessages,
} from '@presentation/components/form';
import style from './style';
import InputMask from 'react-input-mask';
import CheckCep from '@/services/viaCep';
import { CreateCustomerApi, UpdateCustomerApi } from '@/services/customerUpdateApi';
import {Notification} from '@presentation/components/common';

export interface IReceiverProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    data?: any
    setReload: (arg: boolean) => void
    setItemBase: (arg: any) => void 
}

export interface UpdateCustomerInterface {
    id?: number,
    scheduleId: number,
    name: string
    email: string
    registry_code: string
    code: string
    address: {
        street: string
        number: string
        additional_details: string
        zipcode: string
        neighborhood: string
        city: string
        state: string
    }
}

const Receiver = (props: IReceiverProps) => {

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false)

    const [customerName, setCustomerName] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [cpf, setCpf] = useState('')
    const [address, setAddress] = useState('')
    const [numberHome, setNumberHome] = useState('')
    const [complement, setComplement] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')

    async function handleZipCode(zipcode: string){
        if ( zipcode.length > 7 ){
            const address = await CheckCep(zipcode)

            setAddress(address.logradouro ?? '')
            setNeighborhood(address?.bairro ?? '')
            setState(address.uf ?? '')
            setCity(address?.localidade ?? '')
        }
    }

    useEffect(()=>{
        
        setCustomerName(props?.data?.paymentCustomer?.name ?? 'Não Informado')
        setEmail(props?.data?.paymentCustomer?.email ?? '')
        setCep(props?.data?.paymentCustomer?.zipcode ?? '')
        setCpf(props?.data?.paymentCustomer?.registry_code ?? '')
        setAddress(props?.data?.paymentCustomer?.street ?? '')
        setNumberHome(props?.data?.paymentCustomer?.number ?? '')
        setNeighborhood(props?.data?.paymentCustomer?.neighborhood ?? '')
        setState(props?.data?.paymentCustomer?.state ?? '')
        setCity(props?.data?.paymentCustomer?.city ?? '')

    },[props.data])

    async function validateCPF(strCPF: string): Promise<boolean> {
        strCPF = strCPF.replace(/\D/g, '')
    
        if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
            strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' ||
            strCPF === '88888888888' || strCPF === '99999999999' || strCPF.length !== 11) {
            return false;
        }
    
        let Soma: number = 0;
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
    
        let Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }
    
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            return false;
        }
    
        Soma = 0;
        for (let k = 1; k <= 10; k++) {
            Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k);
        }
    
        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }
    
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            return false;
        }
    
        return true;
    }
    
    async function handleUpdateCustomer(){

        if ( customerName.length === 0 || email.length === 0 || cpf.length < 10 || cep.length < 7 ){
            Notification.error({
                message: 'Por favor, verifique os dados informados!',
                duration: 3,
            });

            setError(true)

            return
        }

        const  validadeCpf = await validateCPF(cpf)

        if ( validadeCpf === false ){

            Notification.error({
                message: 'O CPF informado não é valido!',
                duration: 3,
            });

            return
        }

        setError(false)

        const newCustomer : UpdateCustomerInterface = {
            id: +props?.data?.paymentCustomer?.id ?? null,
            scheduleId: +props?.data?.id,
            name: customerName,
            email: email,
            registry_code: cpf,
            code: cpf,
            address: {
                street: address,
                number: numberHome,
                additional_details: complement,
                zipcode: cep,
                neighborhood: neighborhood,
                city: city,
                state: state
            }
        }

        let resCustomerId: number = 0

        if ( props.data?.paymentCustomer?.id === undefined || props.data?.paymentCustomer?.id === 0 ){
            const result = await CreateCustomerApi(newCustomer)
            if ( result !== undefined && result !== 0 ){
                resCustomerId = result
            }
        }else{
            resCustomerId = await UpdateCustomerApi(newCustomer)
        }

        if (resCustomerId !== 0){
            
            Notification.success({
                message: 'Tomador atualizado com sucesso!',
                duration: 2,
            });

            const nItemBase = props.data
                nItemBase.paymentCustomer.id = resCustomerId
                nItemBase.paymentCustomer.name = customerName,
                nItemBase.paymentCustomer.email = email,
                nItemBase.paymentCustomer.registry_code = cpf,
                nItemBase.paymentCustomer.code = cpf,
                nItemBase.paymentCustomer.address = {
                                                        street: address,
                                                        number: numberHome,
                                                        additional_details: complement,
                                                        zipcode: cep,
                                                        neighborhood: neighborhood,
                                                        city: city,
                                                        state: state
                                                    }

            props.setItemBase(nItemBase)
            props.setVisible(false)
            props.setReload(true)

        }else{
            
            Notification.error({
                message: 'Não foi possível atualizar os dados do tomador nesse momento!',
                duration: 3,
            });

        }
    }

    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={
                <Typography.Title style={style.description}>
                    Informe os dados do tomador
                </Typography.Title>
            }
            visible={props.visible}
            centered={true}
            onOk={() => props.setVisible(!props.visible)}
            onCancel={() => props.setVisible(!props.visible)}
            footer={[
                <Button
                    key="cancelar"
                    htmlType="submit"
                    size="middle"
                    loading={loading}
                    style={{
                        borderRadius: 20,
                        color: '#FFFFFF',
                        backgroundColor: '#FF6D6D',
                        border: '1px solid #FFFFFF',
                        boxShadow: '0 0 0 5px #FF6D6D',
                        width: 130,
                        marginTop: 10,
                        marginBottom: 10,
                        marginRight: 20
                    }}
                    onClick={() => props.setVisible(false) }
                >
                    Cancelar
                </Button>,
                <Button
                    key="salvar"
                    htmlType="submit"
                    size="middle"
                    loading={loading}
                    style={{
                        borderRadius: 20,
                        color: '#FFFFFF',
                        backgroundColor: '#43AB66',
                        border: '1px solid #FFFFFF',
                        boxShadow: '0 0 0 5px #43AB66',
                        width: 130,
                    }}
                    onClick={async () => await handleUpdateCustomer() }
                >
                    CONFIRMAR
                </Button>,
            ]}
        >
            <Form
                layout="horizontal"
                validateMessages={validateMessages}
                style={{
                    textAlign: 'center',
                }}
            >
                <Row style={{ marginTop: -30, marginLeft: -10}}>
                    <Col span={24} style={{padding: 20}}>
                        <Form.Item
                            label="Nome"
                            labelCol={{span: 24}}
                        >
                            <Input 
                                style={{
                                    height: 40, 
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe o Nome" 
                                onChange={(e)=>setCustomerName(e.target.value)}
                                value={customerName}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginTop: -60, marginLeft: -10}}>
                    <Col span={24} style={{padding: 20}}>
                        <Form.Item
                            label="E-mail"
                            labelCol={{span: 24}}
                        >
                            <Input 
                                style={{
                                    height: 40, 
                                    marginTop: -15,
                                    textTransform: 'lowercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe o e-mail" 
                                onChange={(e)=>setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginTop: -60, marginLeft: -10}}>
                    <Col span={5} style={{padding: 20}}>
                        <Form.Item
                            label="CPF"
                            labelCol={{span: 24}}
                        >
                            <InputMask 
                                mask="999.999.999-99" 
                                maskChar="_" 
                                onChange={(e) => setCpf(e.target.value)}
                                style={{
                                    marginTop: -15,
                                    borderRadius: 20,
                                    height: 40,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    paddingLeft: 10,
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                value={cpf}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{marginTop: -20}} />

                <Row style={{marginTop: -30, marginLeft: -10}}>
                    <Col span={4} style={{padding: 20}}>
                        <Form.Item
                            label="CEP"
                            labelCol={{span: 24}}
                        >
                            <InputMask 
                                mask="99999-999" 
                                maskChar="_" 
                                onChange={(e)=>setCep(e.target.value)}
                                onBlur={async (e) => await handleZipCode(e.target.value)}
                                value={cep}
                                style={{
                                    marginTop: -15,
                                    borderRadius: 20,
                                    height: 40,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    paddingLeft: 10,
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop: -60, marginLeft: -10}}>
                    <Col span={17} style={{padding: 20}}>
                        <Form.Item
                            label="Rua"
                            labelCol={{span: 24}}
                        >
                            <Input 
                                style={{
                                    height: 40,
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe a rua" 
                                onChange={(e)=>setAddress(e.target.value)}
                                value={address}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7} style={{padding: 20}}>
                        <Form.Item
                            label="Número"
                            labelCol={{span: 24}}
                        >
                            <Input 
                                style={{
                                    height: 40,
                                    width: 100, 
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Número" 
                                onChange={(e)=>setNumberHome(e.target.value)}
                                value={numberHome}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginTop: -60, marginLeft: -10}}>
                    <Col span={24} style={{padding: 20}}>
                        <Form.Item
                            label="Complemento"
                            labelCol={{span: 24}}
                        >
                            <Input 
                                style={{
                                    height: 40, 
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                placeholder="Informe o complemento" 
                                onChange={(e)=>setComplement(e.target.value)}
                                value={complement}
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

        </AntModal>
    );
};

export {Receiver};
