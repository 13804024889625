const container: React.CSSProperties = {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
};

const link: React.CSSProperties = {
    color: '#054B7C',
    textAlign: 'center',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24.5px',
    letterSpacing: '0.4px',
    cursor: 'pointer',
};

const title: React.CSSProperties = {
    fontSize: 24,
    fontWeight: 600,
    color: 'rgb(0, 74, 125)',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 4,
};

const description: React.CSSProperties = {
    fontSize: 14,
    fontWeight: 400,
    color: 'rgb(130, 131, 131)',
    textAlign: 'center',
};

const form: React.CSSProperties = {
    maxWidth: '360px',
    paddingTop: 20,
    margin: '0 auto',
};

export default {
    container,
    link,
    title,
    description,
    form,
};
