import {Form as AntForm} from 'antd';
import InputMask from 'react-input-mask';
import style from './style';
interface IInputPhone {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
}

const InputPhone = (props: IInputPhone) => {
    return (
        <AntForm.Item
            name={props?.name ?? 'phone'}
            label={props?.label ?? 'Telefone'}
            rules={[{required: props.required}]}
        >
            <InputMask
                mask="(99) 99999-9999"
                style={style.phone}
                data-testid="input-phone"
                placeholder={props?.placeholder ?? 'Telefone'}
            />
        </AntForm.Item>
    );
};

export {InputPhone};
