import { IAddressResponse } from "../../address/domain/address.entities";
import { IPatientResponse } from "../../patient/domain/patient.entities";
import { IGuardianResponse } from "../../guardian/domain/guardian.entities";

export enum ProcedureTypeSelect {
  TUSS = 'tuss',
  PROCEDURE = 'procedure',
}

export type TProcedureDateHourLocation = {
  date: Date;
  hour: Date;
  location: string;
};

export type TProcedure = {
  id: string;
  name: string;
  description: string;
  type: ProcedureTypeSelect;
  dateHourLocation: TProcedureDateHourLocation;
};

export type TDataItem = {
  id: string;
  type: ProcedureTypeSelect;
  procedureDate: Date;
  procedureTime: Date;
  procedureLocation: string;
  doctorName: string;
  tussCode: string;
  procedure: any[];
  durationEstimated: Date;
  charge: number;
  healthInsuranceName: string;
  healthInsurancePlan: string;
  healthInsuranceCardCode: string;
  healthInsuranceAccommodation: string;
  price: string;
  patientCpf: string;
  patientFullName: string;
  patientRg: string;
  birthDate: Date;
  patientAge: number;
  patientGenre: string;
  patientEmail: string;
  patientPhone: string;
  patientCountry: string;
  patientInternationalDocument: string;
  responsibleCpf: string;
  responsibleFullName: string;
  responsibleRelationship: string;
  responsibleEmail: string;
  responsiblePhone: string;
  addressZipCode: string;
  addressStreet: string;
  addressNeighborhood: string;
  addressNumber: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  allergieAndPathologies: string;
  filesUploaded: any[];
  observation: string;
}

export type TCreateOrUpdate = {
  type: ProcedureTypeSelect;
  procedureDate: Date;
  procedureTime: Date;
  procedureLocation: string;
  doctorName: string;
  tussCode: string;
  procedure: any[];
  durationEstimated: Date;
  charge: number;
  healthInsuranceName: string;
  healthInsurancePlan: string;
  healthInsuranceCardCode: string;
  healthInsuranceAccommodation: string;
  price: string;
  patientCpf: string;
  patientFullName: string;
  patientRg: string;
  birthDate: Date;
  patientAge: number;
  patientGenre: string;
  patientEmail: string;
  patientPhone: string;
  patientCountry: string;
  patientInternationalDocument: string;
  responsibleCpf: string;
  responsibleFullName: string;
  responsibleRelationship: string;
  responsibleEmail: string;
  responsiblePhone: string;
  addressZipCode: string;
  addressStreet: string;
  addressNeighborhood: string;
  addressNumber: string;
  addressComplement: string;
  addressCity: string;
  addressState: string;
  allergieAndPathologies: string;
  filesUploaded: any[];
  observation: string;
}

export class Procedure {
  constructor(public props: TDataItem) {}

  get data() {
    return this.props;
  }

  get type() {
    return this.props.type;
  }

  get procedureDate() {
    return this.props.procedureDate;
  }

  get procedureTime() {
    return this.props.procedureTime;
  }

  get procedureLocation() {
    return this.props.procedureLocation;
  }

  get doctorName() {
    return this.props.doctorName;
  }

  get tussCode() {
    return this.props.tussCode;
  }

  get procedure() {
    return this.props.procedure;
  }

  get durationEstimated() {
    return this.props.durationEstimated;
  }

  get charge() {
    return this.props.charge;
  }

  get healthInsuranceName() {
    return this.props.healthInsuranceName;
  }

  get healthInsurancePlan() {
    return this.props.healthInsurancePlan;
  }

  get healthInsuranceCardCode() {
    return this.props.healthInsuranceCardCode;
  }

  get healthInsuranceAccommodation() {
    return this.props.healthInsuranceAccommodation;
  }

  get price() {
    return this.props.price;
  }

  get patientCpf() {
    return this.props.patientCpf;
  }

  get patientFullName() {
    return this.props.patientFullName;
  }

  get patientRg() {
    return this.props.patientRg;
  }

  get birthDate() {
    return this.props.birthDate;
  }

  get patientAge() {
    return this.props.patientAge;
  }

  get patientGenre() {
    return this.props.patientGenre;
  }

  get patientEmail() {
    return this.props.patientEmail;
  }

  get patientPhone() {
    return this.props.patientPhone;
  }

  get patientCountry() {
    return this.props.patientCountry;
  }

  get patientInternationalDocument() {
    return this.props.patientInternationalDocument;
  }

  get responsibleCpf() {
    return this.props.responsibleCpf;
  }

  get responsibleFullName() {
    return this.props.responsibleFullName;
  }

  get responsibleRelationship() {
    return this.props.responsibleRelationship;
  }

  get responsibleEmail() {
    return this.props.responsibleEmail;
  }

  get responsiblePhone() {
    return this.props.responsiblePhone;
  }

  get addressZipCode() {
    return this.props.addressZipCode;
  }

  get addressStreet() {
    return this.props.addressStreet;
  }

  get addressNeighborhood() {
    return this.props.addressNeighborhood;
  }

  get addressNumber() {
    return this.props.addressNumber;
  }

  get addressComplement() {
    return this.props.addressComplement;
  }

  get addressCity() {
    return this.props.addressCity;
  }

  get addressState() {
    return this.props.addressState;
  }

  get allergieAndPathologies() {
    return this.props.allergieAndPathologies;
  }

  get filesUploaded() {
    return this.props.filesUploaded;
  }

  get observation() {
    return this.props.observation;
  }

  toJSON() {
    return this.data;
  }
}

// medical procedure
export type TMedicalProcedures = {
  id: number,
  code: string;
  name: string;
  isPrincipal: boolean;
  estimatedTimeSecond: number;
};

// common
export type TSelect = {
  label: string;
  value: string;
};

export type TRadio = {
  title: string;
  value: string;
};


// context
export interface ProcedureContextData {
  states: {
    data:any,
    loading: boolean;
    neighborhood: TSelect[];
    states: TSelect[];
    relationship: TSelect[];
    doctors: TSelect[];
    countries: TSelect[];
    genres: TSelect[];
    procedureLocation: TSelect[];
    procedureTypes: TRadio[];
    chargeMethods: TSelect[];
    medicalProcedures: TMedicalProcedures[];
    searchedAddress: IAddressResponse;
    searchedPatient: IPatientResponse;
    searchedGuardian: IGuardianResponse;
    nationality: NationalityTypeForInterface[];
    
  },
  actions: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleSearchZipCode: (zip: string) => void;
    handleFilterProcedure: (tuss_code?: string, name?: string) => void;
    handleInitLoadSearchGeneral: (id?: string) => Promise<void>;
    handleFindPatientByCpf: (cpf: string) => Promise<any>;
    handleFindGuardianByCpf: (cpf: string) => Promise<any>;
  }
}


// API RESPONSE

type SurgeonsTypeForInterface = {
  id: number,
  name: string | null,
  crm_id: number,
  person_id: number,
  address_id: number,
  commercial_address_id: number,
  surname: string | null,
  created_at: string,
  updated_at: string,
  deleted_at: string | null,
  sap_id: number,
  modality: string,
  observation: string,
  curve: string,
  start_date: string | null,
  payment: string | null,
  preferences: string | null,
  social_media: string | null,
  observation_financial: string | null,
  marital_status: string | null,
  specialist: boolean,
  priority: boolean,
  use_fees_table: boolean,
  installments_number: number | null
};

type UnitsTypeForInterface = {
  id: number,
  name: string,
  hospital_id: number,
  address_id: number,
  color: string,
  created_at: string,
  updated_at: string,
  deleted_at: string | null,
  cnpj: string,
  sap_id: number,
  import_id: number | null,
  fontColor: string,
  initials: string,
  use_psm: boolean | null
};

type ProceduresTypeForInterface = {
  id: number,
  name: string,
  value: string,
  tuss_code: string,
  port: string,
  status: 'ativo' | 'inativo',
  estimated_time: number,
  created_at: string,
  updated_at: string,
  sap_id: number,
  deleted_at: string | null
};

export type PaymentTypesTypeForInterface = {
  id: number,
  name: string,
  type: string,
  is_default: boolean,
  created_at: string,
  updated_at: string,
  deleted_at: string | null
};

type DefaultArrayTypeForInterface = {
  value: string,
  text: string,
}
export type NationalityTypeForInterface = {
  gentilico: string;
  nome_pais: string;
  nome_pais_int: string;
  sigla: string;
}

export interface IScheduleGeneralItens {
  data: any
  schedule: any;
  nationality: NationalityTypeForInterface[];
  surgeons: SurgeonsTypeForInterface[];
  units: UnitsTypeForInterface[];
  procedures: ProceduresTypeForInterface[];
  payment_types: PaymentTypesTypeForInterface[];
  gender: DefaultArrayTypeForInterface[];
  kinship: DefaultArrayTypeForInterface[];
}

export interface IHealthnsurance {
  id: string;
  name: string;
  health_insurance_company_id: string;
}

export interface ICompany {
  id: string;
  name: string;
}