import {Steps as AntSteps} from 'antd';
import './style.css';

interface ISetps {
    id: any;
    onChange: (value: number) => any;
    current: number;
    item: any
}

const StepsButton = (props: ISetps) => {
    
    let iconCloack = 'cloack.svg'
    
    let iconSchedule = ( ( props?.item?.checkIn === null && props?.item?.checkOut === null ) ) ? 'checkout_pending.svg' :
                        ( props?.item?.checkIn !== null && props?.item?.checkOut === null ) ? 'checkin_finished.svg' :
                        ( props?.item?.checkIn !== null && props?.item?.checkOut !== null ) ? 'checkout_finished.svg' : 'checkout_pending.svg'

    let iconPaymeent = props?.item?.statusBilling === 'pending' ? 'paymentPending.svg' : 
        props?.item?.statusBilling === 'paid' ? 'payment.svg' : 'paymentGray.svg'
        
    let iconNf = props?.item?.statusInvoice === 'allpending' ? 'documentGray.svg' : 'document.svg'

    if ( props?.item?.status === 'CANCELADO' ){
        iconCloack = 'error.svg'
        iconSchedule = 'error.svg'
        iconPaymeent = 'error.svg'
        iconNf = 'error.svg'
    }

    if (props?.item?.schedulePaymentTypeId === 5) {
        iconCloack = 'warning-blue.svg'
    }

    return (
        <div style={{cursor: 'not-allowed', width: 123 }}>
            <AntSteps
                size="small"
                current={props.current}
                onChange={props.onChange}
                items={[
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <img
                                src={iconCloack}
                                style={{
                                    cursor: 'not-allowed',
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <img
                                src={iconSchedule}
                                style={{
                                    cursor: 'not-allowed',
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <img
                                src={iconNf}
                                style={{
                                    cursor: 'not-allowed',
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        ),
                    },
                    {
                        title: '',
                        status: 'finish',
                        icon: (
                            <img
                                src={iconPaymeent}
                                style={{
                                    cursor: 'not-allowed',
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default StepsButton;
