import React from 'react';
import {Layout as AntLayout, theme, Drawer} from 'antd';
import style from './style';

import Menu from './menu';
import Profile from '../profile';
import IconClose from './iconClose';
import Footer from './footer';

interface ISidebar {
    defaultSelectedKeys: string;
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSmall: React.Dispatch<React.SetStateAction<boolean>>;
    isSmall: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}

const Sidebar: React.FC<ISidebar> = (props: ISidebar) => {
    const {
        token: {colorPrimary},
    } = theme.useToken();
    const [selectItem, setSelectItem] = React.useState(props.defaultSelectedKeys || '/schedule');

    const onChangeIsSmall = (value: boolean) => {
        props.setIsSmall(value);
        if (value) {
            props.setOpen(false);
        }
    };

    const Content = () => {
        return (
            <>
                <IconClose isSmall={props.isSmall} setOpen={props.setOpen} />
                <Profile />
                <Menu
                    selectItem={selectItem}
                    setSelectItem={setSelectItem}
                    isSmall={props.isSmall}
                    collapsed={props.collapsed}
                />
            </>
        );
    };

    return (
        <>
            <Drawer
                data-testid="sidebar-drawer"
                placement="left"
                closable={false}
                onClose={() => {
                    props.setOpen(!props.open);
                }}
                width={320}
                open={props.open && props.isSmall}
                style={style.drawer(colorPrimary)}
                key="left"
            >
                <Content />
            </Drawer>
            <AntLayout.Sider
                data-testid="sidebar-layout-sider"
                collapsible
                breakpoint="lg"
                collapsed={props.collapsed}
                onCollapse={(value) => props.setCollapsed(value)}
                trigger={null}
                onBreakpoint={(broken) => onChangeIsSmall(broken)}
                style={style.sidebar(colorPrimary)}
                hidden={props.isSmall}
                width={220}
            >
                <Content />

                <Footer collapsed={props.collapsed} setCollapsed={props.setCollapsed} />
            </AntLayout.Sider>
        </>
    );
};

export default Sidebar;
