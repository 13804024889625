import Modal from "@/@presentation/components/common/modal"
import { Button, Typography } from "antd"

interface IConfirmeModel {
  onConfirm: (isConfirm: boolean) => void;
  open: boolean;
}

const ConfirmModal = (props: IConfirmeModel) => {
  const footerConfirmModal: React.ReactNode[] = [
    <Button key='noConfirm' onClick={() => props.onConfirm(false)}>Não</Button>,
    <Button key='yesConfirm' onClick={() => props.onConfirm(true)}>Sim</Button>,
  ]

  return (
    <Modal
      footer={footerConfirmModal}
      open={props.open}
      title='Confirmar procedimento'
    >
      <Typography>Deseja confirmar o procedimento?</Typography>
    </Modal>
  )
}

export default ConfirmModal;