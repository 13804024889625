const card = {
    height: '100%',
    color: '#004A7D',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const procedureTitle = {
    color: '#262A41',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 0.65,
};

const procedureDescription = {
    color: '#00779F',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const img = {marginLeft: 10, marginTop: 0};

const dividerDiv = {
    display: 'flex',
    alignItems: 'center',
    width: 20,
    flexShrink: 0,
    height: 5,
    marginTop: 20,
};

const divider = {
    backgroundColor: '#4675AD',
    height: 5,
};

const text = {
    color: '#828383',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const textDetails = {
    color: '#004A7D',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
};

const takeRow = {marginTop: 20};

const procedureRow = {marginTop: 8, marginBottom: 10};

const row = {marginTop: 20};

const rowName = {marginTop: 4};

const procedureDateRow = {marginTop: 20, marginBottom: 10};

const procedureDate = {
    ...text,
    fontSize: 16,
};

const procedureDateDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 323,
    height: 33,
    flexShrink: 0,
    borderRadius: 20,
    background: '#EDF6F9',
};

const procedureDateDetails: React.CSSProperties = {
    color: '#00A6D0',
    textAlign: 'center',
};

const title = {
    fontSize: 28,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '4',
    color: '#054B7C',
};

const rowTitle = {marginBottom: 10};
export default {
    card,
    procedureTitle,
    procedureDescription,
    procedureDate,
    procedureDateRow,
    img,
    dividerDiv,
    divider,
    takeRow,
    text,
    textDetails,
    procedureRow,
    row,
    rowName,
    procedureDateDiv,
    procedureDateDetails,
    title,
    rowTitle,
};
