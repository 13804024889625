import React, { useEffect, useState } from 'react';
import {Form, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';
import {Notification} from '@presentation/components/common';
import {List, DataType, getFieldsForFilter, getPageAndPerPage} from '@presentation/components/form';
import Header from './header';

import schedule from '@core/modules/schedule/infra/container.registry';
import {Schedule} from '@core/modules/schedule/domain/schedule.entities';
import ButtonsBar from '@/@presentation/components/form/ButtonsBar';
import Status from './status';
import { Receiver } from './receiver';
import StepsButton from './stepsButton';
import { ProcedureValueUpdate } from './procedureValueUpdate';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import { EditOutlined } from '@ant-design/icons';
import { auth } from '@/@core/modules/authentication/infra/container.registry';

const HomeList = () => {
    const navigate = useNavigate();

    const { setOrigin } = useItemsContext()

    setOrigin('')

    const [modalVisible, setModalVisible] = React.useState(false);
    const [receiverVisible, setReceiverVisible] = React.useState(false);
    const [params, setParams] = React.useState<Schedule>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setReload] = React.useState(true);
    const [currentStep, setCurrentStep] = React.useState(0);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const [customerSelected, setSelectedCustomer] = useState<any>()

    const [isBackoffice, setIsBackoffice] = useState(false);

    const [viewProcedureValue, setViewProcedureValue] = useState(false);

    const getUser = async () => {
        const userInfos = await auth.userInfos.execute();
        setIsBackoffice(userInfos?.props.type === 'BACKOFFICE');
    }

    const onChangeRowKey = (selectedRowKeys: React.Key[], selectedRows: Schedule[]) => {
        setSelectedRowKeys(selectedRowKeys);
        const items = selectedRows.map(row => row);
        setSelectedItems(items);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeRowKey,
        getCheckboxProps: (record: any) => ({
            disabled: !record.canEdit && !isBackoffice,
        }),
    };

    const onChangeStep = (value: number) => {
        setCurrentStep(value);
        setReceiverVisible(!receiverVisible);
    };

    async function loadData(params?: any) {
        params.ignoreCanceled = 'true'
        return await schedule.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });
    }

    const rowClickProps = (record: any, columnIndex: number) => {
        if (columnIndex === 1)
        {
            setSelectedCustomer(record)
            setReceiverVisible(true)
        }else if (columnIndex === 2)
        {
            if (
                record?.statusBilling !== 'pending' && 
                record?.statusBilling !== 'paid' &&
                record?.schedulePaymentTypeId !== 5 && 
                record?.status !== 'canceled' &&
                record?.total == 'R$ 0,00' || 
                ( 
                    record?.total != 'R$ 0,00' && 
                    record?.updatedBySecretary === true &&
                    record?.statusBilling !== 'paid' &&
                    record?.statusBilling !== 'pending' && 
                    record?.schedulePaymentTypeId !== 5 
                )
        )
            {
                setSelectedCustomer(record)
                setViewProcedureValue(true)
            }else{
                Notification.error({
                    message: 'Não é possivel alterar o valor desse item!',
                    duration: 2,
                });
            }
        } else {
            setOrigin('payment')
            navigate(`/schedule/${record?.id}`, {
                state: {canEdit: record?.canEdit},
            });
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID ',
            dataIndex: 'id',
            width: 80,
            align: 'center',
            render: (id) => (
                <a>{id}</a>
            ),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (e) => {
                        e.stopPropagation();
                        rowClickProps(record, 0);
                    },
                };
            },
        },
        {
            title: 'Data Procedimento ',
            dataIndex: 'procedureDate',
            sortDirections: ['descend'],
            width: 120,
            align: 'center',
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Unidade',
            dataIndex: 'unit',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Cirurgião',
            dataIndex: 'surgeon',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (surgeon) => (
                <Tooltip placement="topLeft" title={surgeon}>
                    {surgeon?.length > 23 ? `${surgeon.substring(0, 23)}...` : surgeon}
                </Tooltip>
            ),
        },
        {
            title: 'Procedimento',
            dataIndex: 'procedure',
            defaultSortOrder: 'descend',
            render: (procedure) => (
                <Tooltip placement="topLeft" title={procedure}>
                    {procedure?.length > 26 ? `${procedure.substring(0, 26)}...` : procedure}
                </Tooltip>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'total',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (total, record: any) => {
                if (record.canEdit || isBackoffice) {
                    return <a><EditOutlined /> {total}</a>
                }

                return <p>{total}</p>
            },
            onCell: (record: any, rowIndex) => {
                return {
                    onClick: (e) => {
                        if (record.canEdit || isBackoffice) {
                            e.stopPropagation();
                            rowClickProps(record, 2);
                        }
                    },
                };
            },
        },
        {
            title: 'Nome do Tomador',
            dataIndex: 'receiverName',
            defaultSortOrder: 'descend',
            width: 100,
            responsive: ['sm'],
            render: (receiverName, record: any) => {
                if (record?.canEdit || isBackoffice) {
                    return <a><EditOutlined /> {receiverName}</a>
                }

                return <p>{receiverName}</p>
            },
            onCell: (record: any, rowIndex) => {
                return {
                    onClick: (e) => {
                        if (record.canEdit || isBackoffice) {
                            e.stopPropagation();
                            rowClickProps(record, 1);
                        }
                    },
                };
            },
        },
        {
            title: 'Status Pagamento',
            dataIndex: '',
            defaultSortOrder: 'descend',
            width: 100,
            render: (record) => (
                <Status key={record?.id} item={record} />
            ),
            responsive: ['sm'],
        },
        {
            title: 'Baixar NFe',
            align: 'center',
            width: 100,
            render: (record) => (
                <img
                    key={record?.id}
                    src={record?.invoiceUrl ? "/pdfIcon.svg" : "/pdfIconGray.svg"}
                    onClick={() => {
                        if (record?.invoiceUrl) {
                            window.open(record?.invoiceUrl, '_blank');
                        }
                    }}
                    style={{
                        cursor: record?.invoiceUrl ? 'pointer' : 'not-allowed',
                        height: 23,
                        width: 23,
                        alignContent: 'center',
                        alignItems: 'center',
                        opacity: record?.invoiceUrl ? 1 : 0.5,
                    }}
                />
            ),
            responsive: ['sm'],
        },
        {
            title: 'Botões de Ação',
            dataIndex: '',
            key: 'x',
            align: 'center',
            responsive: ['sm'],
            render: (record) => (
                <StepsButton 
                    id={record?._id} 
                    onChange={onChangeStep} 
                    current={currentStep} 
                    item={record} />
            ),
        },
    ]

    useEffect(()=>{
        getUser()
        if ( loading ){
            setSelectedItems([])
            setSelectedRowKeys([])
        }
    },[loading])
    return (
        <>
            <Receiver 
                visible={receiverVisible} 
                setVisible={setReceiverVisible} 
                data={customerSelected}
                setReload={setReload}
                setItemBase={()=>console.log()}
            />

            <ProcedureValueUpdate 
                visible={viewProcedureValue}
                setVisible={setViewProcedureValue} 
                data={customerSelected}
                setReload={setReload}
            />

            <div>
                <List
                    rowSelection={rowSelection}
                    columns={columns}
                    loadData={loadData}
                    Form={Form}
                    setLoading={setLoading}
                    loading={loading}
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    setParams={setParams}
                    params={params}
                    setReload={setReload}
                    isReload={isReload}
                    showStatusBillingFilter
                    headerChildren={<Header hideBtnSchedule={true} />}
                />

                <ButtonsBar 
                    selectedItems={selectedItems}
                    nfNumber={0}
                />
            </div>
        </>
    );
};

export default HomeList;
