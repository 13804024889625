import {Form as AntForm, Input, InputProps} from 'antd';

interface IInputDefault {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    nativeProps?: InputProps;
}

const InputDefault = (props: IInputDefault) => {
    return (
        <AntForm.Item
            name={props?.name ?? 'name'}
            label={props?.label ?? 'Nome'}
            rules={[{required: props?.required ?? false}]}
        >
            <Input
                {...props?.nativeProps}
                size="large"
                style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    borderRadius: 20,
                    ...props?.nativeProps?.style,
                }}
                maxLength={255}
                data-testid="input-name"
                placeholder={props?.placeholder ?? 'Nome'}
            />
        </AntForm.Item>
    );
};

export {InputDefault};
