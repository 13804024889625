import {Form as AntForm} from 'antd';
import InputMask from 'react-input-mask';
import style from './style';

type ErrorType = {
    isError: boolean;
    message: string;
}
interface IInputRg {
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    error?: ErrorType;
}

const InputRg = (props: IInputRg) => {
    return (
        <AntForm.Item
            name={props?.name ?? 'RG'}
            label={props?.label ?? 'RG'}
            rules={[{required: props.required}]}
            help={props.error?.isError && props.error.message}
        >
            <InputMask
                mask="99.999.999-9"
                style={{...style.cpf, borderColor: props.error?.isError ? '#FF0000' : '#D9D9D9'}}
                data-testid="input-name"
                placeholder={props?.placeholder ?? 'Cpf'}
                onChange={props.onChange}
            />
        </AntForm.Item>
    );
};

export {InputRg};
