import React from 'react';
import {Button, Form, Tooltip, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';

import {List, DataType, getFieldsForFilter, getPageAndPerPage} from '@presentation/components/form';
import Header from './header';

import schedule from '@core/modules/schedule/infra/container.registry';
import {Schedule} from '@core/modules/schedule/domain/schedule.entities';
import Status from './status';
import Modal from '@/@presentation/components/common/modal';

const ScheduleList = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<Schedule>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setReload] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const onConfirm = () => {
        const ids = selectedItems.map((item) => item.id)
        schedule.confirm.execute(ids)
        navigate(0)
    }

    const footer: React.ReactNode[] = [
        <Button key='no' onClick={() => setOpenModal(false)}>Não</Button>,
        <Button key='yes' onClick={onConfirm}>Sim</Button>,
    ]

    const onChangeRowKey = (selectedRowKeys: React.Key[], selectedRows: Schedule[]) => {
        setSelectedRowKeys(selectedRowKeys);
        const items = selectedRows.map(row => row);
        setSelectedItems(items);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeRowKey,
        getCheckboxProps: (record: Schedule) => ({
            disabled: record.status !== 'PENDENTE' // || !record.canEdit,
        }),
    };

    async function loadData(params?: any) {
        return await schedule.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });
    }

    const rowClickProps = (record: Schedule) => {
        navigate(`/schedule/${record?.id}`, {
            state: {canEdit: record?.canEdit},
        });
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID ',
            dataIndex: 'id',
            width: 80,
            align: 'center',
            render: (id) => (
                <a>{id}</a>
            ),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (e) => {
                        e.stopPropagation();
                        rowClickProps(record, 0);
                    },
                };
            },
        },
        {
            title: 'Data Procedimento ',
            dataIndex: 'procedureDate',
            sortDirections: ['descend'],
            width: 120,
            align: 'center',
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Unidade',
            dataIndex: 'unit',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Cirurgião',
            dataIndex: 'surgeon',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (surgeon) => (
                <Tooltip placement="topLeft" title={surgeon}>
                    {surgeon?.length > 23 ? `${surgeon.substring(0, 23)}...` : surgeon}
                </Tooltip>
            ),
        },
        {
            title: 'Procedimento',
            dataIndex: 'procedure',
            defaultSortOrder: 'descend',
            render: (procedure) => (
                <Tooltip placement="topLeft" title={procedure}>
                    {procedure?.length > 26 ? `${procedure.substring(0, 26)}...` : procedure}
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            defaultSortOrder: 'descend',
            width: 100,
            render: (record) => (
                <Status key={record?.id} status={record} />
            ),
            responsive: ['sm'],
        },
    ]

    return (
        <>
        <Modal
            footer={footer}
            open={openModal}
            title='Confirme'
        >
            <Typography>Tem certeza que deseja confirmar todos os agendamentos selecionados?</Typography>
        </Modal>
            <List
                rowSelection={rowSelection}
                columns={columns}
                loadData={loadData}
                Form={Form}
                setLoading={setLoading}
                loading={loading}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setParams={setParams}
                params={params}
                setReload={setReload}
                isReload={isReload}
                headerChildren={<Header />}
                showStatusFilter
            />
            <div style={{  padding: 10 }}>
            <div style={{ textAlign: 'right' }}>
                <Button
                    style={{
                        borderRadius: 20,
                        color: '#FFFFFF',
                        backgroundColor: rowSelection.selectedRowKeys.length !== 0 ? '#43AB66' : '#BABBAF',
                        border: '1px solid #FFFFFF',
                        boxShadow: rowSelection.selectedRowKeys.length !== 0 ?  '0 0 0 5px #43AB66' :  '0 0 0 5px #BABBAF',
                        width: 200,
                        marginRight: 20
                    }}
                    onClick={() => setOpenModal(true)}
                >
                    Confirmar procedimento
                </Button>
            </div>
        </div>
        </>
    );
};

export default ScheduleList;
