import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Layout, Row, Select, Space, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LoadingOutlined } from '@ant-design/icons';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import {Notification} from '@presentation/components/common';
import { ModalConfirmationNf } from './ModalConfirmationNf';
import { Receiver } from '../home/receiver';
import invoice from '@/@core/modules/invoice/infra/container.registry';
import { ModalConfirmationPayment } from './ModalConfirmationPayment';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface paymentOptionsInterface {
    label: string,
    value: string
}

const NfProcess = () => {

    const navigate = useNavigate()

    const { items, setItems } = useItemsContext()
    const [loading, setLoading] = React.useState(false)
    const [defineValues, setDefineValues] = useState(false)
    const [defaultInfo, setDefaultInfo] = useState('')
    const [intallentNumber, setIntallentNumber] = useState(0)

    const [receiverVisible, setReceiverVisible] = useState(false)

    const [paymentType, setPaymentType] = useState('')

    const [error, setError] = useState(false)

    const [paymentOptions, setPaymentOptions] = useState<paymentOptionsInterface[]>([])
    const [installmentsNumber, setInstallmensNumber] = useState<paymentOptionsInterface[]>([])

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showConfirmationPayment, setShowConfirmationPayment] = useState(false)

    const [itemBase, setItemBase] = useState<any>()

    useEffect(()=>{

        if ( items[0]?.installments_number === 0 || items[0]?.installments_number === undefined ){

            setPaymentOptions([
                { label: 'PIX', value: 'pix' },
                { label: 'BOLETO', value: 'bank_slip' },
            ])

        }else{

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' },
                { label: 'CARTÃO DE CRÉDITO', value: 'credit_card' }
            ])

            const newInstallments = [];
            for (let i = 1; i <= items[0].installments_number; i++) {
                newInstallments.push({ label: `${i}`, value: `${i}` });
            }

            setInstallmensNumber(newInstallments)
        }

        if ( items[0]?.methodCode !== '' ){
            setPaymentType(items[0]?.methodCode ?? '')
            setIntallentNumber(items[0]?.paymentInstallmentsNumber ?? 0)
        }

        setItemBase(items[0])

        handleDefineValues()

    },[items])

    function handleDefineValues() {

        if (!defaultInfo) {
            setDefineValues(true)

            const txtHeader = 'Serviços de Anestesia Prestados a paciente:'
            const txtFooter = '[1]Valor aproximado dos tributos 15,55%.\nConforme Lei nº 12741 de 08.12.2012'

            const groupedByPatient = items.reduce((acc, item) => {
                // const { patient, procedure, procedureDate, total } = item;
                const { anesthetist, patient, procedureDate, total } = item;
                if (!acc[patient]) {
                    acc[patient] = [];
                }
                // acc[patient].push(`Data da Cirurgia: ${procedureDate} - ${total}`);
                acc[patient].push(`Anestesista: ${anesthetist} \nData da Cirurgia: ${procedureDate}`);
                return acc;
            }, {});

            const formattedData = Object.entries(groupedByPatient).map(([patient, procedures]) => {
                const formattedPatient = `${patient.replace(':', '').toUpperCase()}`;
                const formattedProcedures = procedures.join('\n');
                return `${formattedPatient}\n\n\n${formattedProcedures}`;
            }).join('\n\n');

            const totalValue = items.reduce((acc, item) => {

                if (!item.total) {
                    return acc;
                }

                const totalWithoutCurrency = item.total.replace(/[^\d,]/g, '');
                const totalWithDot = totalWithoutCurrency.replace(',', '.');

                const parsedTotal = parseFloat(totalWithDot);
                return isNaN(parsedTotal) ? acc : acc + parsedTotal;

            }, 0);

            // const formattedTotal = totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

            // const fullText = `${txtHeader}\n\n${formattedData}\n\n\nTotal: ${formattedTotal}\n\n\n${txtFooter}`;
            const fullText = `${txtHeader}\n\n${formattedData}\n\n\n${txtFooter}`;

            setDefaultInfo(fullText)
        }
    }

    async function handleRequestNf() {

        const customerName = itemBase?.paymentCustomer?.name ?? ''
        const customerEmail = itemBase?.paymentCustomer?.email ?? ''
        const customerCpf = itemBase?.paymentCustomer?.registry_code ?? ''
        const customerStreet = itemBase?.paymentCustomer?.address?.street ?? ''
        const customerNumberHome = itemBase?.paymentCustomer?.address?.number ?? ''
        const customerCep = itemBase?.paymentCustomer?.address?.zipcode ?? ''

        if ( customerName.length === 0 ||
                customerEmail.length === 0 ||
                customerCpf.length === 0 ||
                customerStreet.length === 0 ||
                customerNumberHome.length === 0 ||
                customerCep.length === 0
            ){

            Notification.error({
                message: 'Por favor informar os campos obrigatórios do tomador!',
                duration: 5,
            });

            return
        }else if ( paymentType.length === 0 || defaultInfo.length === 0 ){
            setError(true)
            Notification.error({
                message: 'Por favor verifique a forma de pagamento e as Observações de encerramento!',
                duration: 3,
            });

        }else{
            setError(false)
            setShowConfirmation(true)
        }

    }

    async function handleConfirmation() {

        setLoading(true)

        const nfs: InvoiceInterface[] = items.map((item) => {
            return {
                schedule_id: item?.id,
                cpf: item?.paymentCustomer?.registry_code,
                date: item?.scheduleDate,
                hospital: item?.unit,
                patient: item?.patient,
                text: defaultInfo,
                value: item?.total,
                email: item?.paymentCustomer?.email,
                method_code: paymentType,
                installments_number: intallentNumber ?? 0,
                payment_billing_id: item?.paymentBillingId ?? 0,
                schedule_surgery_id: item?.scheduleSurgeryId ?? 0
            }
        })

        const res = await invoice.create.execute(nfs)

        if ( res ){

            items[0].statusInvoice = 'Solicitado'
            items[0].methodCode = paymentType
            items[0].paymentInstallmentsNumber = intallentNumber ?? 0
            setItems(items)

            Notification.success({
                message: 'Solicitação e NF realizada com sucesso!',
                duration: 3,
            });

            setLoading(false)
            setShowConfirmation(false)

            if ( items.filter((f)=>f.statusBilling !== 'allpending' ).length === 0 ){
                setShowConfirmationPayment(true)
            }else{
                navigate('/home')
            }

        }else{

            Notification.error({
                message: 'Não foi possível solicitar a NF nesse momento!',
                duration: 3,
            });

            setLoading(false)
            setShowConfirmation(false)
        }

    }

    async function handleConfirmationPayment(){
        navigate('/paymentprocess')
    }

    async function handleCancelPayment(){
        setShowConfirmationPayment(false)
        navigate('/home')
    }

    return (

        <Layout
            data-testid="layout-schedule-view"
            style={{
                height: '80vh',
                backgroundColor: 'white',
            }}
        >
            <Content className="site-layout-content">
                <Content className="pd-24">
                    <Spin spinning={loading} indicator={antIcon} tip="Carregando..."></Spin>

                    <Row justify="start">
                        <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                            <Space size="small" style={{ display: 'flex' }}></Space>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                            <Form.Item></Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 60 }}>
                        <p style={{
                            fontSize: 28,
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '4',
                            // color: '#054B7C'
                            color: '#101010'
                        }}>Nota Fiscal</p>
                    </Row>

                    <Row>
                        <p style={{
                            fontSize: 18,
                            fontStyle: 'normal',
                            lineHeight: '4',
                            color: '#101010'
                        }}>Emitir nota fiscal</p>
                    </Row>

                    <Row style={{marginBottom: 10, marginTop: -20}}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Tomador</label>
                    </Row>

                    <Row style={{marginBottom: 30, marginTop: 10}}>
                        <label style={{ marginLeft: '5%', fontSize: 15, backgroundColor: '#f5f5f5', paddingTop: 9, paddingBottom: 9, paddingLeft: 15, width: 500, borderRadius: 20 }}>{itemBase?.paymentCustomer?.name.toUpperCase()}</label>
                        <Button
                            style={{marginLeft: 20, borderColor: '#054B7C', borderRadius: 20, color: '#054B7C'}}
                            onClick={()=>setReceiverVisible(true)}
                        >Alterar Tomador</Button>
                    </Row>

                    <Row style={{ marginBottom: 20 }}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Observação de NF</label>
                        <textarea
                            rows={20}
                            style={{
                                width: '90%',
                                marginLeft: '5%',
                                borderRadius: 20,
                                marginTop: 10,
                                marginBottom: 30,
                                padding: 15,
                                fontSize: 16,
                                resize: 'none',
                                borderColor: error ? '#ff0000' : '#054B7C',
                            }}
                            disabled={!defineValues}
                            onChange={(e) => setDefaultInfo(e.target.value)}
                            value={defaultInfo}
                        >
                        </textarea>
                    </Row>

                    <Row style={{marginBottom: 10, marginTop: -20}}>
                        <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold' }}>Forma de pagamento</label>
                    </Row>

                    <Row style={{marginLeft: '5%'}}>
                        <Form.Item
                            validateStatus={error ? 'error' : ''}
                            // help={error ? 'Por favor, selecione uma forma de pagamento' : null}
                        >
                            <Select
                                style={{
                                    height: 40,
                                    width: 400,
                                    marginBottom: 10
                                }}
                                placeholder="Selecione a forma de pagamento"
                                options={paymentOptions}
                                onChange={(e)=>setPaymentType(e)}
                                value={paymentType === 'bank_slip' ? 'BOLETO' :
                                    paymentType === 'pix' ? 'PIX' :
                                    paymentType === 'credit_card' ? 'CARTÃO DE CRÉDITO' : '' }
                                disabled={items.filter((f)=>f.advancePayment === true).length > 0 ||
                                    items[0]?.methodCode === 'pix' ||
                                    items[0]?.methodCode === 'bank_slip' ||
                                    items[0]?.methodCode === 'credit_card'
                                    ? true : false}
                            />
                        </Form.Item>
                    </Row>

                    <Row style={{marginLeft: '5%', display: paymentType === 'credit_card' ? 'flex' : 'none'}}>
                        <Row style={{marginBottom: 10, width: 200}}>
                            <label style={{ fontSize: 15 }}>Quantidade de parcelas</label>
                        </Row>
                        <Row style={{width: '100%'}}>

                        <Form.Item>
                                <Select
                                    style={{height: 40, width: 200}}
                                    placeholder="Selecione as parcelas"
                                    options={installmentsNumber}
                                    onChange={(e)=>setIntallentNumber(e)}
                                    disabled={items.filter((f)=>f.advancePayment === true).length > 0 ? true : false}
                                />
                            </Form.Item>
                        </Row>

                    </Row>

                    <Row style={{ marginTop: 20, position: 'absolute', right: 140}} className="justify-content-end">
                        <Button
                            style={{
                                borderRadius: 20,
                                color: '#FFFFFF',
                                backgroundColor: '#FF6D6D',
                                border: '1px solid #FFFFFF',
                                boxShadow: '0 0 0 5px #FF6D6D',
                                width: 150,
                                marginRight: 20
                            }}
                            onClick={() => navigate('/home')}
                        >
                            Cancelar
                        </Button>

                        <Button
                            style={{
                                borderRadius: 20,
                                color: '#FFFFFF',
                                backgroundColor: '#43AB66',
                                border: '1px solid #FFFFFF',
                                boxShadow: '0 0 0 5px #43AB66',
                                width: 150,
                            }}
                            onClick={() => handleRequestNf()}
                        >
                            OK
                        </Button>
                    </Row>

                </Content>
            </Content>

            <ModalConfirmationNf
                visible={showConfirmation}
                children={undefined} title={'Confirmar dados'}
                handleCancel={()=>setShowConfirmation(false)}
                handleOk={async ()=> await handleConfirmation()}
                paymentCustomerName={itemBase?.paymentCustomer?.name.toUpperCase() || 'Não informado'}
                paymentType={paymentType === 'bank_slip' ? 'BOLETO' :
                                paymentType === 'pix' ? 'PIX' :
                                paymentType === 'credit_card' ? 'CARTÃO DE CRÉDITO' : 'NÃO INFORMADO'}
            />

            <ModalConfirmationPayment
                visible={showConfirmationPayment}
                children={undefined} title={'Realizar Pagamento'}
                handleCancel={()=>handleCancelPayment()}
                handleOk={async ()=> await handleConfirmationPayment()}
            />

            <Receiver
                visible={receiverVisible}
                setVisible={setReceiverVisible}
                data={itemBase}
                setReload={()=>console.log()}
                setItemBase={setItemBase}
            />
        </Layout>
    );
};

export default NfProcess;
